import formbricks from '@formbricks/js/app';
import React, { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { useAuth } from './AuthProvider';

interface Props {
  children: React.ReactNode;
}

export const FormbricksProvider: React.FC<Props> = ({ children }) => {
  const { pathname } = useLocation();
  const { currentUser, userFullName } = useAuth();
  const searchParams = useSearchParams();

  useEffect(() => {
    if (import.meta.env.VITE_FORMBRICKS_ENV_ID && currentUser) {
      formbricks.init({
        environmentId: import.meta.env.VITE_FORMBRICKS_ENV_ID,
        apiHost: 'https://formbricks.digitalpricingtool.com',
        userId: currentUser.id,
        attributes: {
          name: userFullName,
          email: currentUser.email,
        },
      });
    }
  }, [currentUser, userFullName]);

  useEffect(() => {
    formbricks?.registerRouteChange();
  }, [pathname, searchParams]);

  return <>{children}</>;
};
