import { Container, List, ListItem, styled, Typography } from '@mui/material';
import React from 'react';

import dealGPTSplashImage from '../../../assets/dealgpt-splash-image.jpg';

const NoAccessContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '24px',
  backgroundColor: '#ffffff',
  margin: 'auto',
  padding: '24px',
});

const BulletedListItem = styled(ListItem)({
  display: 'list-item',
  paddingLeft: 2,
});

const ListTypography = styled(Typography)({
  fontSize: '18px',
});

const NoAccess = (): JSX.Element => (
  <NoAccessContainer>
    <Typography variant="h4" sx={{ fontWeight: 600, textAlign: 'center' }}>
      Intellio® Advantage combines multiple assets into one powerful Generative AI Asset
    </Typography>
    <img src={dealGPTSplashImage} alt="DealGPT Splash Image" width="100%" />
    <Typography variant="h6" sx={{ textAlign: 'center', width: '90%' }}>
      Using Glean’s knowledge base and Nigel’s secure environment, we can build any custom user
      interface to snap to our processes within West Monroe.
    </Typography>
    <div style={{ width: '90%' }}>
      <ListTypography align="left">This allows us to:</ListTypography>
      <List sx={{ listStyleType: 'disc' }}>
        <BulletedListItem>
          <ListTypography>
            Create applications that have siloed knowledge on a set corpus of documents while
            leveraging existing rights and access on these documents
          </ListTypography>
        </BulletedListItem>
        <BulletedListItem>
          <ListTypography>
            Build a user interface that leverages behind the scenes prompting to gather or evaluate
            content within the application
          </ListTypography>
        </BulletedListItem>
        <BulletedListItem>
          <ListTypography>
            Expose a chat option that will allow you to question this set corpus of documents
          </ListTypography>
        </BulletedListItem>
        <BulletedListItem>
          <ListTypography>
            Leverage our internal Intellio Insights data set for quick data exposure and analysis
          </ListTypography>
        </BulletedListItem>
      </List>
    </div>
  </NoAccessContainer>
);

export default NoAccess;
