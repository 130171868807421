import { NotInterestedOutlined } from '@mui/icons-material';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import SyncRoundedIcon from '@mui/icons-material/SyncRounded';
import { styled, Typography } from '@mui/material';
import React from 'react';

import { DealGPTQuestionStatus } from '../../Types/enums';

const QuestionStatusContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  borderRadius: '4px',
  padding: '4px 8px',
});

type IconProps = {
  iconColor: string;
};

const Icon = styled('div')<IconProps>(({ iconColor }) => ({
  display: 'flex',
  alignItems: 'center',

  svg: {
    color: iconColor,
    fontSize: '16px',
  },
}));

const statusInfo = {
  [DealGPTQuestionStatus.ANSWERED]: {
    text: 'ANSWERED',
    icon: <CheckCircleOutlinedIcon />,
    iconColor: '#1F6D12',
    bgColor: '#CFEAD2',
  },
  [DealGPTQuestionStatus.PENDING]: {
    text: 'PENDING',
    icon: <SyncRoundedIcon />,
    iconColor: '#135996',
    bgColor: '#D3EAFF',
  },
  MORE_INFO_NEEDED: {
    text: 'MORE INFO NEEDED',
    icon: <ReportProblemOutlinedIcon />,
    iconColor: '#B66D03',
    bgColor: '#FFE9CA',
  },
  [DealGPTQuestionStatus.ERROR]: {
    text: 'ERROR',
    icon: <ReportProblemOutlinedIcon />,
    iconColor: '#E02F10',
    bgColor: '#FFD4CA',
  },
  [DealGPTQuestionStatus.NOT_FOUND]: {
    text: 'NOT FOUND',
    icon: <ReportProblemOutlinedIcon />,
    iconColor: '#E02F10',
    bgColor: '#FFD4CA',
  },
  [DealGPTQuestionStatus.SKIPPED]: {
    text: 'SKIPPED',
    icon: <NotInterestedOutlined />,
    iconColor: '#567b9c',
    bgColor: 'lightgrey',
  },
};

type QuestionStatusProps = {
  status: DealGPTQuestionStatus;
};

const QuestionStatus = ({ status }: QuestionStatusProps): JSX.Element => {
  const { text, icon, iconColor, bgColor } = statusInfo[status];

  return (
    <div style={{ display: 'inline-block' }}>
      <QuestionStatusContainer style={{ backgroundColor: bgColor }}>
        <Icon iconColor={iconColor}>{icon}</Icon>
        <Typography sx={{ fontSize: '0.875rem', fontWeight: '700', whiteSpace: 'nowrap' }}>
          {text}
        </Typography>
      </QuestionStatusContainer>
    </div>
  );
};

export default QuestionStatus;
