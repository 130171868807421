import { Attachment, Send } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField, Tooltip, Typography } from '@mui/material';
import { PayloadAction } from '@reduxjs/toolkit';
import { closeSnackbar, useSnackbar } from 'notistack';
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

// import { v4 as uuidv4 } from 'uuid';
import ChatBubbleComponent from '../components/Chat/ChatBubble';
import ChatFeedback from '../components/Chat/ChatFeedback';
import ChatInfo from '../components/Chat/ChatInfo';
import FileCard from '../components/Chat/FileCard';
import LimitWarning from '../components/Chat/LimitWarning';
import OutputWarning from '../components/Chat/OutputWarning';
import ReusePromptBanner from '../components/Chat/ReusePromptBanner';
import ToolSettings from '../components/Chat/ToolSettings';
import NavDrawer from '../components/Drawer/NavDrawer';
import NavBar, { NavBarMode } from '../components/NavBar';
// import { useAnalytics } from '../components/Providers/AnalyticsProvider';
import { useAuth } from '../components/Providers/AuthProvider';
import { useFeatures } from '../components/Providers/FeatureProvider';
import {
  getConversation,
  getConversations,
  getMessages,
  getStreamingResponse,
  postConversation,
  postFile,
  postMessage,
  setCurrentMessagesAsync,
  updateConversation,
} from '../redux/actions/conversationActions';
import { findPrompt } from '../redux/actions/promptActions';
import { selectAppStatus, setCurrentApp } from '../redux/reducers/appStatusReducer';
import {
  selectConversations,
  setCurrentConversation,
  setCurrentNumTokens,
  setIsLoading,
} from '../redux/reducers/conversationReducer';
import { AppDispatch } from '../redux/store';
import {
  ChatContainer,
  ChatRow,
  drawerWidth,
  MainContainer,
  PageContainer,
  SendButton,
  StyledChatContainer,
} from '../theme/CustomComponents';
import { Conversation, Message, MessageFile } from '../Types/conversation';
import { AppEnum, MessageFrom, ToolName } from '../Types/enums';
import { Prompt } from '../Types/prompt';

export type ChatProps = {
  gleanApplicationId?: string;
};

const Chat = ({ gleanApplicationId }: ChatProps): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const features = useFeatures();
  const { currentUser } = useAuth();
  const { conversationId } = useParams();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch<AppDispatch>();
  const { currentConversation, currentMessages, isLoading } = useSelector(selectConversations);
  const { selectedApp, llm } = useSelector(selectAppStatus);

  const messageContainerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>();
  const [promptId, setPromptId] = useState<string>();
  const [prompt, setPrompt] = useState<Prompt>();
  const [showErrorMessage, setShowErrorMessage] = useState('');
  const [historyDrawerOpen, setHistoryDrawerOpen] = useState<boolean>(
    window.matchMedia('(min-width: 960px)').matches
  );
  const [tools, setTools] = useState<ToolName[]>([]);
  const [showBanners, setshowBanners] = useState<boolean>(true);
  const [showChatInfo, setShowChatInfo] = useState<boolean>(false);

  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<MessageFile[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(true);
  const snackbar30Messages = useRef<string | number>();
  const currentMessagesRef = useRef(currentMessages);

  // const analytics = useAnalytics();

  const handleFileDelete = (index: number) => {
    setUploadedFiles((uploadedFiles) => {
      return [...uploadedFiles.slice(0, index), ...uploadedFiles.slice(index + 1)];
    });
  };

  const handleTextChange = (index: number, newText: string) => {
    setUploadedFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles[index] = { ...newFiles[index], contents: newText };
      return newFiles;
    });
  };

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      enqueueSnackbar('No file selected.', { variant: 'error', autoHideDuration: 3000 });
      return;
    }

    const file = e.target.files[0];

    // Clear target value to allow the same filename
    // to be uploaded in succession.
    e.target.value = '';

    // Validate file size before uploading to server.
    const fileSizeMB = file.size / 1024 ** 2;
    if (fileSizeMB > 200) {
      enqueueSnackbar('Upload Failed. File size too large. Max size: 200MB.', {
        variant: 'error',
        autoHideDuration: 3000,
      });
      return;
    }

    const index = filesToUpload.length;
    setFilesToUpload((filesToUpload) => [...filesToUpload, file]);

    (() => {
      const formData = new FormData();
      formData.append('file', file);
      try {
        dispatch(postFile(formData)).then((action: PayloadAction<MessageFile | unknown>) => {
          const messageFile = action.payload as MessageFile;

          if (messageFile.statusText) {
            setFilesToUpload((filesToUpload) => [
              ...filesToUpload.slice(0, index),
              ...filesToUpload.slice(index + 1),
            ]);
            enqueueSnackbar('Network timeout error or file is empty', {
              variant: 'error',
              autoHideDuration: null,
            });
            throw Error(messageFile.statusText);
          }

          if (messageFile.contents === '') {
            handleFileDelete(index);
          }

          setUploadedFiles((uploadedFiles) => [...uploadedFiles, messageFile]);

          setFilesToUpload((filesToUpload) => [
            ...filesToUpload.slice(0, index),
            ...filesToUpload.slice(index + 1),
          ]);
        });
      } catch (error) {
        handleFileDelete(index);
      }
    })();
  };

  const handleStreamingResponse = useCallback(
    (response: Response) => {
      if (response && response.body) {
        const reader = response.body.getReader();
        const decoder = new TextDecoder();

        const initBotMessage = {
          conversationId: currentConversation?.id ?? '',
          from: MessageFrom.AI,
          message: '',
        } as Message;

        dispatch(setCurrentMessagesAsync([...currentMessagesRef.current, initBotMessage])).then(
          async () => {
            setUploadedFiles([]);

            let done, value;
            let result = '';

            do {
              ({ done, value } = await reader.read());

              if (done) {
                break;
              }

              const chunk = decoder.decode(value, { stream: true });
              const doneIndex = chunk.indexOf('NEW CONVERSATION ID:');

              if (doneIndex >= 0) {
                const newId = chunk.slice(doneIndex + 21);

                dispatch(setIsLoading(false));

                if (window.location.pathname === '/chat') {
                  dispatch(getConversations());
                }

                dispatch(setCurrentConversation(undefined));
                navigate(`/chat/${newId}`);
              } else {
                result += chunk;
                const newMessages = [...currentMessagesRef.current];
                newMessages[newMessages.length - 1] = {
                  ...newMessages[newMessages.length - 1],
                  message: result,
                };
                dispatch(setCurrentMessagesAsync(newMessages));
              }
            } while (!done);
          }
        );
      }
    },
    [currentConversation?.id, navigate, dispatch]
  );

  const setChatPrompt = (prompt: string) => {
    if (inputRef.current) {
      inputRef.current.value = prompt;
    }
  };

  const getChatPrompt = () => {
    if (inputRef.current) {
      return inputRef.current.value;
    }
    return '';
  };

  const submitMessage = useCallback(
    (message: string, files: MessageFile[], conversationId: string, prompt?: Prompt) => {
      // This function is here so that we can set the currentMessages if needed, but we can avoid it for an autosend prompt
      // we need to use the dispatch().then() pattern if we're doing both, but there's not an easy way to conditionalize that without a lot of duplication
      const actuallySubmitMessage = () => {
        if (features.streaming && selectedApp !== AppEnum.BIA && selectedApp !== AppEnum.DALLE) {
          dispatch(
            getStreamingResponse({
              message: { conversationId, message, files },
              userId: currentUser?.id ?? '',
              llm,
            })
          ).then((action: PayloadAction<Response | unknown>) => {
            handleStreamingResponse(action.payload as Response);
          });
        } else {
          dispatch(
            postMessage({
              message: { conversationId, message, files },
              llm,
              gleanApplicationId,
            })
          ).then(() => {
            if (window.location.pathname === '/chat') {
              dispatch(getConversations());
            }

            dispatch(getMessages(conversationId)).then(
              (action: PayloadAction<Message[] | unknown>) => {
                dispatch(setCurrentMessagesAsync(action.payload as Message[])).then(() => {
                  dispatch(setIsLoading(false));
                  navigate(`/chat/${conversationId}`);
                });
              }
            );
          });
        }
      };
      if (prompt && !prompt.userEditRequired) {
        actuallySubmitMessage();
      } else {
        const newMessages = [
          ...currentMessagesRef.current,
          {
            conversationId,
            message,
            from: MessageFrom.USER,
            createdAt: '',
            id: '',
            deleted: false,
            tokens: 0,
            files,
          },
        ];
        currentMessagesRef.current = newMessages;
        dispatch(setCurrentMessagesAsync(newMessages)).then(actuallySubmitMessage);
      }
    },
    [
      dispatch,
      features.streaming,
      selectedApp,
      currentUser?.id,
      llm,
      handleStreamingResponse,
      gleanApplicationId,
      navigate,
    ]
  );

  const createNewConversation = useCallback(
    (
      message: string,
      tempUploadedFiles: MessageFile[],
      newConversationTitle: string,
      prompt?: Prompt
    ) => {
      dispatch(
        postConversation({
          title: newConversationTitle,
          promptId: searchParams.get('prompt') ?? undefined,
          tools: [...tools, ...(prompt?.tools ?? [])],
          appName: prompt ? prompt.appName : selectedApp,
        })
      ).then((action: PayloadAction<Conversation | unknown>) => {
        const newConversation = action.payload as Conversation;

        submitMessage(message, tempUploadedFiles, newConversation.id, prompt);
      });
    },
    [dispatch, searchParams, tools, selectedApp, submitMessage]
  );

  const handleSubmit = useCallback(
    (prompt?: Prompt, input?: string) => {
      if (input && conversationId) {
        dispatch(setIsLoading(true));
        submitMessage(input, uploadedFiles, conversationId, prompt);
      } else if (inputRef.current) {
        const ref = inputRef.current;

        if (ref.value === '' && uploadedFiles.length === 0) {
          setShowErrorMessage('Please enter your message!');
          setTimeout(() => {
            setShowErrorMessage('');
          }, 5000);
          return;
        }

        dispatch(setIsLoading(true));
        setShowChatInfo(false);
        const message = getChatPrompt();

        const tempUploadedFiles = [...uploadedFiles];
        setUploadedFiles([]);
        ref.value = '';

        if (!conversationId) {
          let newConversationTitle;
          if (prompt?.title) {
            newConversationTitle = prompt.title;
          } else if (tempUploadedFiles.length > 0) {
            const fileName = tempUploadedFiles[0].name;
            if (fileName.length > 20) {
              const extension = fileName.slice(((fileName.lastIndexOf('.') - 1) >>> 0) + 2);
              newConversationTitle = `${fileName.substring(0, 20)}...${extension}`;
            } else {
              newConversationTitle = fileName;
            }
          } else {
            newConversationTitle = message;
          }

          createNewConversation(message, tempUploadedFiles, newConversationTitle, prompt);
        } else {
          submitMessage(message, tempUploadedFiles, conversationId, prompt);
        }
      }
      if (inputRef.current) {
        inputRef.current.focus();
      }
    },
    [dispatch, uploadedFiles, conversationId, createNewConversation, submitMessage]
  );

  //triggers when the prompt id changes in URL
  useEffect(() => {
    const newPromptId = searchParams.get('prompt');
    if (newPromptId) {
      dispatch(setIsLoading(true));
      setPromptId(newPromptId);
    } else {
      setPromptId(undefined);
      setPrompt(undefined);
    }
  }, [searchParams, dispatch]);

  //fetches the prompt had been found in URL
  useEffect(() => {
    if (promptId && !prompt) {
      const fetchPrompt = () => {
        dispatch(findPrompt(promptId)).then((action: PayloadAction<Prompt | unknown>) => {
          const newPrompt = action.payload as Prompt;
          setPrompt(newPrompt);
          setPromptId(undefined);
          setChatPrompt(newPrompt.prompt);
          setTools(newPrompt.tools ?? []);
          dispatch(setIsLoading(false));

          if (!newPrompt.userEditRequired) {
            handleSubmit(newPrompt);
          } else {
            dispatch(setIsLoading(false));
          }
        });
      };
      fetchPrompt();
    }
  }, [handleSubmit, navigate, prompt, promptId, dispatch]);

  //triggers when a different conversation is selected
  useEffect(() => {
    if (currentConversation?.id !== conversationId) {
      if (conversationId) {
        dispatch(setIsLoading(true));
        dispatch(getConversation(conversationId))
          .then((action: PayloadAction<Conversation | unknown>) => {
            const newConversation = action.payload as Conversation;

            dispatch(setCurrentConversation(newConversation));

            let newTools = newConversation.tools ?? [];
            setTools(newTools);
            newTools = Array.from(new Set(newTools.concat(newConversation?.prompt?.tools ?? [])));

            setUploadedFiles([]);
            dispatch(setCurrentApp(newConversation.appName));
            if (inputRef.current) {
              inputRef.current.value = '';
            }

            dispatch(getMessages(conversationId)).then(
              (action: PayloadAction<Message[] | unknown>) => {
                const newMessages = action.payload as Message[];
                if (newConversation.prompt && newConversation.prompt.userEditRequired === false) {
                  if (newMessages.length > 0) {
                    newMessages.shift();
                  }
                }

                if (newMessages.length >= 30 && snackbarOpen) {
                  snackbar30Messages.current = enqueueSnackbar(
                    'Warning: This conversation has more than 30 messages. Please consider starting a new conversation.',
                    {
                      variant: 'warning',
                      autoHideDuration: null,
                      preventDuplicate: true,
                      onClose: () => setSnackbarOpen(false),
                    }
                  );
                }

                dispatch(setCurrentMessagesAsync(newMessages)).then(() => {
                  setSnackbarOpen(true);
                  const mostRecentAIMessages = newMessages.filter(
                    (message) => message.from === MessageFrom.AI
                  );
                  dispatch(
                    setCurrentNumTokens(
                      mostRecentAIMessages.length > 0
                        ? mostRecentAIMessages[mostRecentAIMessages.length - 1].tokens
                        : 0
                    )
                  );
                  dispatch(setIsLoading(false));
                });
              }
            );
          })
          .catch((e) => {
            console.log(e);
            enqueueSnackbar('Error fetching conversation', { variant: 'error' });
            dispatch(setCurrentConversation(undefined));
          });
      } else {
        dispatch(setCurrentConversation(undefined));
        setTools([]);
        setUploadedFiles([]);
      }
    }
  }, [
    conversationId,
    currentConversation,
    enqueueSnackbar,
    snackbarOpen,
    dispatch,
    navigate,
    isLoading,
  ]);

  // clear the current conversation when there isn't a conversationId
  useEffect(() => {
    if (currentConversation?.id && !conversationId) {
      dispatch(setCurrentMessagesAsync([]));
      setChatPrompt('');
      setPrompt(undefined);
      dispatch(setCurrentNumTokens(0));
      dispatch(setCurrentConversation(undefined));
    }
  }, [conversationId, currentConversation, dispatch]);

  // scroll to the bottom of the messages when anything updates
  useEffect(() => {
    const scrollToBottom = () => {
      if (messageContainerRef.current) {
        messageContainerRef.current.scrollTo({
          top: messageContainerRef.current.scrollHeight,
          behavior: 'smooth',
        });
      }
    };

    // Ensure the scroll happens after the DOM update
    setTimeout(scrollToBottom, 100);
  }, [dispatch, currentMessages]);

  // updates the currentMessagesRef when currentMessages updates
  useEffect(() => {
    currentMessagesRef.current = currentMessages;
  }, [currentMessages]);

  // triggers when a different app is selected
  useEffect(() => {
    if (selectedApp === AppEnum.DALLE) {
      setShowChatInfo(true);
      setshowBanners(false);
    } else if (selectedApp === AppEnum.ENERGY_UTILITIES_DEMO) {
      setShowChatInfo(false);
      setshowBanners(false);
    } else {
      const shouldShow = window.location.pathname === '/chat';
      setShowChatInfo(shouldShow);
      setshowBanners(shouldShow);
    }
  }, [navigate, selectedApp]);

  const updateTools = useCallback(
    (toolName: ToolName[]) => {
      setTools(toolName);
      if (conversationId) {
        dispatch(updateConversation({ updatedFields: { tools: toolName }, conversationId })).then(
          () => dispatch(getConversations())
        );
      }
    },
    [conversationId, dispatch]
  );

  useEffect(() => {
    return () => {
      if (snackbar30Messages.current) {
        closeSnackbar(snackbar30Messages.current);
      }
    };
  }, [snackbar30Messages]);

  // useEffect(() => {
  //   analytics.chatMode({ app: appState.selectedApp });
  // }, [analytics, appState.selectedApp]);

  return (
    <PageContainer>
      <NavDrawer
        drawerOpen={historyDrawerOpen}
        setDrawerOpen={setHistoryDrawerOpen}
        drawerWidth={drawerWidth}
      />
      <MainContainer open={historyDrawerOpen}>
        <NavBar mode={NavBarMode.NIGEL} />
        <div
          style={{ overflow: 'auto', width: '100%', minHeight: 'calc(100% - 64px)' }}
          ref={messageContainerRef}
        >
          <StyledChatContainer maxWidth="md">
            <div
              style={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '20px',
              }}
            >
              <ChatInfo
                currentConversation={currentConversation}
                currentPrompt={prompt}
                shouldShowInfo={showChatInfo}
                showBanners={showBanners}
              />
            </div>
            <ChatContainer>
              {currentMessagesRef.current.length === 0 && !prompt?.id && (
                <ChatBubbleComponent
                  llm={llm}
                  key={'placeholder'}
                  message={{
                    from: MessageFrom.AI,
                    message:
                      'Start a new conversation with me by typing in the box or select an old conversation from the left! 😊',
                  }}
                  handleSubmit={handleSubmit}
                />
              )}
              {currentMessagesRef.current.length === 0 && prompt && prompt.userEditRequired && (
                <>
                  <ChatBubbleComponent
                    llm={llm}
                    key={'placeholder'}
                    message={{
                      from: MessageFrom.AI,
                      message: `Looks like you're using a prompt, make sure to edit it if necessary before sending.`,
                    }}
                    handleSubmit={handleSubmit}
                  />
                </>
              )}
              {currentMessagesRef.current.map((message, index) => (
                <ChatBubbleComponent
                  llm={llm}
                  key={index}
                  message={message}
                  handleSubmit={handleSubmit}
                />
              ))}
              {currentMessagesRef.current.length > 0 &&
                currentMessagesRef.current[currentMessagesRef.current.length - 1].from ===
                  MessageFrom.AI &&
                !isLoading && (
                  <ChatFeedback
                    currentMessage={
                      currentMessagesRef.current[currentMessagesRef.current.length - 1]
                    }
                  />
                )}
              {isLoading &&
                ((currentMessagesRef.current.length > 0 &&
                  currentMessagesRef.current[currentMessagesRef.current.length - 1].from ===
                    MessageFrom.USER) ||
                  currentMessagesRef.current.length === 0) && (
                  <ChatBubbleComponent
                    llm={llm}
                    key={'loading'}
                    message={{ from: MessageFrom.AI }}
                    handleSubmit={handleSubmit}
                  />
                )}
              <ChatRow>
                <div style={{ paddingBottom: '20px', flexGrow: 1 }}>
                  <TextField
                    inputRef={(ref) => (inputRef.current = ref)}
                    variant="outlined"
                    label="Please enter a message"
                    InputProps={{
                      inputProps: {
                        style: { fontSize: '14px' },
                      },
                      endAdornment: (
                        <>
                          {selectedApp !== AppEnum.BIA && selectedApp !== AppEnum.DALLE && (
                            <InputAdornment position="start">
                              <ToolSettings
                                tools={tools}
                                disabledOnTools={currentConversation?.prompt?.tools ?? []}
                                handleToolsUpdate={updateTools}
                              />
                            </InputAdornment>
                          )}
                          <InputAdornment
                            position="end"
                            sx={{ alignSelf: 'end', marginBottom: '12px' }}
                          >
                            {features.file_upload_button && selectedApp !== AppEnum.DALLE && (
                              <Tooltip
                                PopperProps={{ keepMounted: true }}
                                id={'chat-upload-document-label'}
                                title={
                                  <Typography variant="caption">
                                    {' '}
                                    Upload a document. 200MB limit.{' '}
                                  </Typography>
                                }
                              >
                                <IconButton
                                  component="label"
                                  style={{ left: '8px' }}
                                  aria-labelledby={'chat-upload-document-label'}
                                >
                                  <input type="file" hidden onChange={handleFileUpload} />
                                  <Attachment />
                                </IconButton>
                              </Tooltip>
                            )}
                          </InputAdornment>
                        </>
                      ),
                    }}
                    multiline
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    error={!!showErrorMessage}
                    helperText={showErrorMessage}
                    onKeyDown={(e) => {
                      if (
                        e.key === 'Enter' &&
                        !e.shiftKey &&
                        filesToUpload.length === 0 &&
                        !isLoading
                      ) {
                        e.preventDefault();
                        handleSubmit(prompt);
                      }
                    }}
                    autoComplete="off"
                  />
                  <div>
                    {filesToUpload.map((fileToUpload: File, index: number) => (
                      <div key={index} style={{ padding: '5px' }}>
                        <FileCard
                          fileToUpload={fileToUpload}
                          handleFileDelete={() => {
                            handleFileDelete(index);
                          }}
                          llm={llm}
                        />
                      </div>
                    ))}
                    {uploadedFiles.map((uploadedFile: MessageFile, index: number) => (
                      <div key={index} style={{ padding: '5px' }}>
                        <FileCard
                          uploadedFile={uploadedFile}
                          handleFileDelete={() => {
                            handleFileDelete(index);
                          }}
                          handleTextChange={(newText) => {
                            handleTextChange(index, newText);
                          }}
                          llm={llm}
                        />
                      </div>
                    ))}
                  </div>
                  {features.advanced_token_tracking_display ? (
                    <>
                      <LimitWarning />
                      {currentConversation?.promptId && currentMessagesRef.current.length > 1 && (
                        <ReusePromptBanner promptId={currentConversation.promptId} />
                      )}
                    </>
                  ) : (
                    <>
                      <OutputWarning />
                      {currentConversation?.promptId && currentMessagesRef.current.length > 1 && (
                        <ReusePromptBanner promptId={currentConversation.promptId} />
                      )}
                    </>
                  )}
                </div>
                <div
                  style={{
                    display: 'flex',
                    height: '100%',
                    paddingBottom:
                      currentConversation?.promptId && currentMessages.length > 1
                        ? uploadedFiles.length > 0
                          ? '84px'
                          : '80px'
                        : uploadedFiles.length > 0
                        ? '62px'
                        : '58px',
                    boxSizing: 'border-box',
                  }}
                >
                  <Tooltip
                    id={'send-button-label'}
                    PopperProps={{ keepMounted: true }}
                    title={<Typography variant="caption"> Send </Typography>}
                  >
                    <span>
                      <SendButton
                        aria-labelledby={'send-button-label'}
                        onClick={() => handleSubmit(prompt)}
                        color="primary"
                        disabled={filesToUpload.length > 0 || isLoading}
                      >
                        <Send />
                      </SendButton>
                    </span>
                  </Tooltip>
                </div>
              </ChatRow>
            </ChatContainer>
          </StyledChatContainer>
        </div>
      </MainContainer>
    </PageContainer>
  );
};

export default Chat;
