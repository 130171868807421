import { styled } from '@mui/material';
import React, { ReactNode } from 'react';

import NavBar, { NavBarMode } from '../../../components/NavBar';
import { drawerWidth, MainContainer } from '../../../theme/CustomComponents';
import NavDrawer from '../../Drawer/NavDrawer';

const DealGPTContainer = styled('div')({
  display: 'flex',
  height: 'inherit',
  overflowX: 'hidden',

  div: {
    position: 'relative',
  },
});

export type DealGPTContainerViewProps = {
  historyDrawerOpen: boolean;
  setHistoryDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  children: ReactNode;
};

const DealGPTContainerView = ({
  historyDrawerOpen,
  setHistoryDrawerOpen,
  children,
}: DealGPTContainerViewProps): JSX.Element => {
  return (
    <DealGPTContainer>
      <NavDrawer
        drawerWidth={drawerWidth}
        drawerOpen={historyDrawerOpen}
        setDrawerOpen={setHistoryDrawerOpen}
        showNewChatButton={false}
      />
      <MainContainer open={historyDrawerOpen}>
        <NavBar mode={NavBarMode.DEALGPT} />
        {children}
      </MainContainer>
    </DealGPTContainer>
  );
};

export default DealGPTContainerView;
