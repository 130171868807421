import CheckIcon from '@mui/icons-material/Check';
import { CircularProgress, ListItem, styled, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { ProjectRunAnalysisQuestion } from '../../../Types/dealGPT';
import UnprocessedChangesDot from './UnprocessedChangesDot';

type NavListItemProps = {
  current: boolean;
  previouslySeen: boolean;
};

const navListItemTriangleSize = 20;

const NavListItem = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== 'current' && prop !== 'previouslySeen',
})<NavListItemProps>(({ current, previouslySeen }) => ({
  alignItems: 'center',
  backgroundColor: previouslySeen ? (current ? '#D3EAFF' : '#70AD47') : '#FAF8F2',
  color: previouslySeen && !current ? '#ffffff' : '#757575',
  cursor: previouslySeen ? 'pointer' : 'auto',
  display: 'flex',
  flex: '1 1 auto',
  justifyContent: 'center',
  textAlign: 'center',
  padding: '12px',
  paddingRight: '16px',

  '&:first-of-type': {
    borderRadius: '100px 0px 0px 100px',

    '&::after': {
      left: window.matchMedia('(min-width: 1200px)').matches ? '50%' : '75%',
      borderRightWidth: window.matchMedia('(min-width: 1200px)').matches
        ? `${navListItemTriangleSize + 4}px`
        : `${navListItemTriangleSize - 4}px`,
    },
  },

  '&:last-of-type': {
    borderRadius: '0px 100px 100px 0px',

    '&::after': {
      left: window.matchMedia('(min-width: 1200px)').matches ? '50%' : '25%',
      borderLeftWidth: window.matchMedia('(min-width: 1200px)').matches
        ? `${navListItemTriangleSize + 4}px`
        : `${navListItemTriangleSize - 4}px`,
    },
  },

  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: `-${navListItemTriangleSize - 2}px`,
    left: '50%',
    transform: 'translateX(-50%)',
    borderLeft: `${navListItemTriangleSize + 4}px solid transparent`,
    borderRight: `${navListItemTriangleSize + 4}px solid transparent`,
    borderTop: `${navListItemTriangleSize - 2}px solid ${current ? '#D3EAFF' : 'transparent'}`,
    borderBottom: 'none',
  },
}));

type AnalysisNavItemProps = {
  index: number;
  pageName: string;
  isLoadingThis: boolean;
  isLoadingApplyChanges: boolean;
  stepIndex: number;
  highestStepIndex: number;
  setStepIndex: React.Dispatch<React.SetStateAction<number>>;
  setHighestStepIndex: React.Dispatch<React.SetStateAction<number>>;
  pageQuestions: ProjectRunAnalysisQuestion[];
};

const AnalysisNavItem: React.FC<AnalysisNavItemProps> = ({
  index,
  pageName,
  isLoadingThis,
  isLoadingApplyChanges,
  stepIndex,
  highestStepIndex,
  setStepIndex,
  setHighestStepIndex,
  pageQuestions,
}) => {
  const [visited, setVisited] = useState<boolean>(false);
  const [hasUnprocessedDownstreamChanges, setHasUnprocessedDownstreamChanges] =
    useState<boolean>(false);

  const handleNavClick = (index: number) => {
    if (index < highestStepIndex + 1) {
      setStepIndex(index);

      if (index > highestStepIndex) {
        setHighestStepIndex(index);
      }
    }
  };

  useEffect(() => {
    if (index === stepIndex) {
      setVisited(true);
    }
  }, [index, stepIndex]);

  useEffect(() => {
    let maxDisplayOrder = 0;
    setHasUnprocessedDownstreamChanges(false);

    for (const question of pageQuestions) {
      if (question.displayOrder && question.displayOrder > maxDisplayOrder) {
        maxDisplayOrder = question.displayOrder;
      }
    }

    for (let i = 0; i <= maxDisplayOrder; i++) {
      const displayOrderQuestions = pageQuestions.filter((question) => question.displayOrder === i);

      if (
        displayOrderQuestions.length > 0 &&
        displayOrderQuestions[0].unprocessedDownstreamChanges === true
      ) {
        setHasUnprocessedDownstreamChanges(true);
        // If one question on the page has unprocessed downstream changes, no need to keep searching
        return;
      }
    }
  }, [pageQuestions]);

  return (
    <NavListItem
      current={index === stepIndex}
      previouslySeen={visited}
      key={`${pageName}.nav`}
      onClick={() => handleNavClick(index)}
    >
      {hasUnprocessedDownstreamChanges && (
        <UnprocessedChangesDot position={{ top: '16px', right: '5px' }} />
      )}
      {!isLoadingThis && !isLoadingApplyChanges && visited && index !== stepIndex && (
        <CheckIcon sx={{ fontSize: '20px', marginRight: '5px' }} />
      )}
      {(index === stepIndex && isLoadingThis) || (index > stepIndex && isLoadingApplyChanges) ? (
        <CircularProgress size="20px" sx={{ marginRight: '5px' }} />
      ) : null}
      <Typography sx={{ fontSize: '14px', fontWeight: '300', flex: 1 }}>{pageName}</Typography>
    </NavListItem>
  );
};

export default AnalysisNavItem;
