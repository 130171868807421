import React, { ReactNode, useState } from 'react';

import { useProjects } from '../../Providers/ProjectsProvider';
import DealGPTContainerView from '../Views/DealGPTContainerView';
import NoAccess from '../Views/NoAccess';

export type DealGPTContainerProps = {
  children: ReactNode;
};

const DealGPTContainer = ({ children }: DealGPTContainerProps): JSX.Element => {
  const [historyDrawerOpen, setHistoryDrawerOpen] = useState<boolean>(
    window.matchMedia('(min-width: 1000px)').matches
  );

  const { hasProjects } = useProjects();

  return (
    <DealGPTContainerView
      historyDrawerOpen={historyDrawerOpen}
      setHistoryDrawerOpen={setHistoryDrawerOpen}
    >
      {hasProjects ? children : <NoAccess />}
    </DealGPTContainerView>
  );
};

export default DealGPTContainer;
