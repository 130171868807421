import ChevronRightSharpIcon from '@mui/icons-material/ChevronRightSharp';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  styled,
  TableContainer,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import type { ProjectRunAnalysisQuestion } from '../../../../Types/dealGPT';
import UnprocessedChangesDot from '../UnprocessedChangesDot';
import RubricOverallRating from './RubricOverallRating';
import RubricRow from './RubricRow';

const Section = styled('div')({
  '&:not(:first-of-type)': {
    borderTop: '2px solid #C4C4C4',
    paddingTop: '40px',
  },
});

const RubricAccordionSummary = styled(AccordionSummary)({
  flexDirection: 'row-reverse',
  paddingLeft: 0,
  paddingBottom: '20px',

  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },

  '& .MuiAccordionSummary-content': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: '24px',
  },
});

const separator = '$$$-$$$';
interface RubricSectionProps {
  areaName: string;
  dimensions: ProjectRunAnalysisQuestion[][];
  reprocessingTriggered: () => void;
  setIsLoadingThis: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoadingApplyChanges: React.Dispatch<React.SetStateAction<boolean>>;
  expandAll: boolean;
}

const RubricSection: React.FC<RubricSectionProps> = ({
  areaName,
  dimensions,
  reprocessingTriggered,
  setIsLoadingThis,
  setIsLoadingApplyChanges,
  expandAll,
}) => {
  const [dimensionString, setDimensionString] = useState<string>('');
  const [dimensionValues, setDimensionValues] = useState<{ [dimension: string]: number }>({});
  const [overallRating, setOverallRating] = useState<number>(0);
  const [hasUnprocessedDownstreamChanges, setHasUnprocessedDownstreamChanges] =
    useState<boolean>(false);
  const [expandAccordion, setExpandAccordion] = useState<boolean>(expandAll);

  const calculateOverallRating = (dimensionValues: number[]) => {
    let definedLength = 0;
    const total = dimensionValues.reduce((acc, cur) => {
      if (cur > 0) {
        definedLength += 1;
      }
      return acc + cur;
    }, 0);
    // avoid division by zero
    if (definedLength === 0) {
      return 0;
    }
    return total / definedLength;
  };

  const toggleAccordion = () => {
    setExpandAccordion(!expandAccordion);
  };

  useEffect(() => {
    setExpandAccordion(expandAll);
  }, [expandAll]);

  useEffect(() => {
    const joinedDimensions = dimensions.map((d) => d[0].dimension).join(', ');
    // console.log('dimensionString', joinedDimensions);
    setDimensionString(joinedDimensions);
  }, [dimensions]);

  useEffect(() => {
    const dimensionValuesObj: { [dimension: string]: number } = {};
    dimensions.forEach((d) => {
      if (d[0].dimension && d[0].value && d[0].value !== '') {
        dimensionValuesObj[d[0].dimension] = parseInt(d[0].value || '0');
      }
    });
    // console.log(areaName, 'dimensionValuesObj', dimensionValuesObj);
    setDimensionValues(dimensionValuesObj);
  }, [dimensions]);

  useEffect(() => {
    setOverallRating(calculateOverallRating(Object.values(dimensionValues)));
  }, [dimensionValues]);

  return (
    <Accordion
      square
      disableGutters
      sx={{ boxShadow: 'none' }}
      expanded={expandAccordion}
      onChange={toggleAccordion}
    >
      <RubricAccordionSummary expandIcon={<ChevronRightSharpIcon />}>
        <div>
          <Typography sx={{ fontWeight: '700', color: '#092B49' }}>{areaName}</Typography>
          <Typography sx={{ fontWeight: '300', color: '#092B49' }}>{dimensionString}</Typography>
        </div>
        <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
          <RubricOverallRating rating={overallRating} />
          {hasUnprocessedDownstreamChanges && (
            <UnprocessedChangesDot position={{ right: '-20px' }} />
          )}
        </div>
      </RubricAccordionSummary>
      <AccordionDetails sx={{ paddingBottom: 0 }}>
        <TableContainer sx={{ marginLeft: '24px', width: '98%' }}>
          {dimensions.map((dimensionVersions) => (
            <Section key={dimensionVersions[0].dimension}>
              <RubricRow
                key={dimensionVersions[0].dimension}
                dimensionName={dimensionVersions[0].dimension as string}
                textfieldName={`${areaName}${separator}${dimensionVersions[0].dimension}`}
                selectName={`${areaName}${separator}${dimensionVersions[0].dimension}${separator}value`}
                versions={dimensionVersions}
                reprocessingTriggered={reprocessingTriggered}
                setIsLoadingThis={setIsLoadingThis}
                setIsLoadingApplyChanges={setIsLoadingApplyChanges}
                setDimensionValues={setDimensionValues}
                setSectionUnprocessedDownstreamChanges={setHasUnprocessedDownstreamChanges}
              />
            </Section>
          ))}
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
};

export default RubricSection;
