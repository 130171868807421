import { keyframes, styled, Typography } from '@mui/material';
import { PayloadAction } from '@reduxjs/toolkit';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import WmCompass from '../assets/wm-compass.png';
import NavDrawer from '../components/Drawer/NavDrawer';
import NavBar, { NavBarMode } from '../components/NavBar';
import { getConversations, getSharedConversation } from '../redux/actions/conversationActions';
import { AppDispatch } from '../redux/store';
import {
  drawerWidth,
  MainContainer,
  PageContainer,
  StyledChatContainer,
} from '../theme/CustomComponents';
import { Conversation } from '../Types/conversation';

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const ChatIcon = styled('img')({
  width: '20px',
});

const SpinningChatIcon = styled(ChatIcon)({
  animation: `${rotateAnimation} 2s linear infinite`,
});

const SharedConversation = (): JSX.Element => {
  const { conversationId } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  useEffect(() => {
    if (conversationId) {
      dispatch(getSharedConversation(conversationId)).then(
        (action: PayloadAction<Conversation | unknown>) => {
          const isConversation = (payload: Conversation | unknown): payload is Conversation => {
            return (payload as Conversation).id !== undefined;
          };

          if (!isConversation(action.payload)) {
            enqueueSnackbar('There was an error accessing the conversation', {
              variant: 'error',
            });
            return navigate('/chat');
          }

          const newConversation = action.payload;
          dispatch(getConversations()).then(() => {
            return navigate(`/chat/${newConversation.id}`);
          });
        }
      );
    }
  }, [conversationId, dispatch, navigate]);

  return (
    <PageContainer>
      <NavDrawer drawerOpen={true} drawerWidth={drawerWidth} setDrawerOpen={() => null} />
      <MainContainer open={true}>
        <NavBar mode={NavBarMode.NIGEL} />
        <div style={{ overflow: 'auto', width: '100%', minHeight: 'calc(100% - 64px)' }}>
          <StyledChatContainer maxWidth="md">
            <div style={{ marginTop: '80px', textAlign: 'center' }}>
              <Typography
                variant="h4"
                sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}
              >
                <SpinningChatIcon
                  src={WmCompass}
                  alt="West Monroe Compass"
                  sx={{ marginRight: '10px', width: '40px' }}
                />{' '}
                Copying Shared Conversation...
              </Typography>
            </div>
          </StyledChatContainer>
        </div>
      </MainContainer>
    </PageContainer>
  );
};

export default SharedConversation;
