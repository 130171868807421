import { AppEnum, Tag, ToolName } from '../Types/enums';
import { TagCounts } from '../Types/tags';

export const CONVERSATION_LIST_SIZE = 15;
export const PROMPT_PAGE_SIZE = 10;

export const INITIAL_TAG_DEFAULTS = Object.values(Tag).reduce((acc, tag) => {
  acc[tag] = 0;
  return acc;
}, {} as TagCounts);

export const PROMPT_DEFAULTS = {
  title: '',
  description: '',
  prompt: '',
  userEditRequired: false,
  published: false,
  metadata: { temperature: 0.5 },
  tags: [] as Tag[],
  tools: [] as ToolName[],
  featured: false,
  appName: AppEnum.NIGEL,
};
