import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

interface DrawerState {
  isOpen: boolean;
}

const initialState: DrawerState = {
  isOpen: window.innerWidth >= 1000,
};

const drawerSlice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    setDrawerOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
    },
  },
});

export const { setDrawerOpen } = drawerSlice.actions;

export const selectDrawerOpen = (state: RootState) => state.drawer.isOpen;

export default drawerSlice.reducer;
