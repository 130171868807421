import {
  CloseFullscreen,
  InsertDriveFileOutlined,
  OpenInFull,
  RemoveCircleOutline,
} from '@mui/icons-material';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  IconButton,
  styled,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import { AzureIndexCitation } from '../../Types/azureIndexCitations';
import { MessageFile } from '../../Types/conversation';
import { Llm } from '../../Types/enums';

const IMAGE_MIME_TYPE_ALLOWLIST = [
  'image/png',
  'image/jpeg',
  'image/jpg',
  'image/gif',
  'image/webp',
];

const StyledCard = styled(Card)({
  height: '100%',
  marginRight: 'auto',
});

export type FileCardProps = {
  fileToUpload?: File;
  uploadedFile?: MessageFile;
  azureCitations?: AzureIndexCitation;
  handleFileDelete?: () => void;
  handleTextChange?: (newText: string) => void;
  llm?: Llm;
};

export const createCitationFilepath = (citation: AzureIndexCitation, index: number) => {
  let citationFilename = '';

  if (citation.filepath && citation.chunk_id) {
    if (citation.filepath.length > 50) {
      const citationLength = citation.filepath.length;
      citationFilename = `${citation.reindex_id}. ${citation.filepath.substring(
        0,
        20
      )}...${citation.filepath.substring(citationLength - 20)} - Part ${
        parseInt(citation.chunk_id) + 1
      }`;
    } else {
      citationFilename = `${citation.reindex_id}. ${citation.filepath} - Part ${
        parseInt(citation.chunk_id) + 1
      }`;
    }
  } else if (citation.filepath && citation.reindex_id) {
    citationFilename = `${citation.reindex_id}. ${citation.filepath} - Part ${citation.reindex_id}`;
  } else {
    citationFilename = `Citation ${index}`;
  }
  return citationFilename;
};

export default function FileCard({
  fileToUpload,
  uploadedFile,
  azureCitations,
  handleFileDelete,
  handleTextChange,
  llm,
}: FileCardProps) {
  const [viewFile, setViewFile] = useState(false);
  const [localText, setLocalText] = useState(uploadedFile ? uploadedFile.contents : '');

  useEffect(() => {
    setLocalText(uploadedFile ? uploadedFile.contents : '');
  }, [uploadedFile]);

  const handleSave = () => {
    if (handleTextChange) {
      handleTextChange(localText);
      setViewFile(false);
    }
  };

  const handleCancel = () => {
    setLocalText(uploadedFile ? uploadedFile.contents : '');
    setViewFile(false);
  };

  const shouldHideContentsDrawer =
    uploadedFile &&
    IMAGE_MIME_TYPE_ALLOWLIST.includes(uploadedFile.mimetype) &&
    llm === Llm.GPT_4O_128K;

  return (
    <StyledCard>
      <CardHeader
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingTop: '10px',
          paddingBottom: '10px',
        }}
        title={
          <div style={{ display: 'flex' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {fileToUpload ? (
                <CircularProgress size={24} style={{ marginRight: '10px' }} />
              ) : (
                <InsertDriveFileOutlined fontSize="small" style={{ marginRight: '10px' }} />
              )}
              <Typography variant="caption" fontSize="small">
                {uploadedFile ? uploadedFile.name : ''}
                {fileToUpload ? fileToUpload.name : ''}
                {azureCitations
                  ? createCitationFilepath(azureCitations, Number(azureCitations.reindex_id))
                  : ''}
                {(uploadedFile || fileToUpload) && (
                  <span style={{ color: 'grey', marginLeft: '5px' }}>
                    {uploadedFile ? Math.ceil((uploadedFile.size / (1024 * 1024)) * 10) / 10 : ''}
                    {fileToUpload ? Math.ceil((fileToUpload.size / (1024 * 1024)) * 10) / 10 : ''}
                    {'MB'}
                  </span>
                )}
              </Typography>
            </div>

            {!shouldHideContentsDrawer && (
              <>
                {viewFile ? (
                  <IconButton
                    data-testid="hide-file-contents"
                    onClick={() => setViewFile(false)}
                    size="small"
                    sx={{ left: '10px', marginLeft: '5px' }}
                  >
                    <Tooltip title="Hide File Contents">
                      <CloseFullscreen fontSize="small" />
                    </Tooltip>
                  </IconButton>
                ) : (
                  (uploadedFile || azureCitations) && (
                    <IconButton
                      data-testid="view-file-contents"
                      onClick={() => setViewFile(true)}
                      size="small"
                      sx={{ left: '10px', marginLeft: '5px' }}
                    >
                      <Tooltip title="View File Contents">
                        <OpenInFull fontSize="small" />
                      </Tooltip>
                    </IconButton>
                  )
                )}
              </>
            )}

            {handleFileDelete && uploadedFile && (
              <IconButton
                onClick={() => handleFileDelete()}
                size="small"
                sx={{ left: '10px', marginLeft: '5px' }}
              >
                <Tooltip title="Delete File">
                  <RemoveCircleOutline fontSize="small" />
                </Tooltip>
              </IconButton>
            )}
          </div>
        }
      ></CardHeader>

      {shouldHideContentsDrawer && (
        <Typography
          variant="caption"
          component="p"
          fontSize="small"
          style={{ padding: '0 16px 10px' }}
        >
          Your file will be sent as an image, not as text
        </Typography>
      )}

      {viewFile &&
        uploadedFile &&
        (handleTextChange ? (
          <CardContent
            sx={{
              paddingTop: '0px',
              paddingBottom: '17px',
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
            }}
          >
            <TextField
              value={localText}
              onChange={(event) => setLocalText(event.target.value)}
              sx={{
                whiteSpace: 'pre-wrap',
              }}
              multiline
              fullWidth
            ></TextField>
            <div style={{ display: 'flex', gap: '10px' }}>
              <Button variant="contained" sx={{ width: '10px' }} onClick={handleSave}>
                Save
              </Button>
              <Button variant="text" sx={{ width: '10px' }} onClick={handleCancel}>
                Cancel
              </Button>
            </div>
          </CardContent>
        ) : (
          <CardContent sx={{ paddingTop: '0px', paddingBottom: '10px' }}>
            <Typography variant="caption" sx={{ whiteSpace: 'pre-wrap' }}>
              {uploadedFile.contents}
            </Typography>
          </CardContent>
        ))}
      {viewFile && azureCitations && (
        <CardContent sx={{ paddingTop: '0px', paddingBottom: '10px' }}>
          <Typography variant="caption" sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
            {azureCitations.content}
          </Typography>
        </CardContent>
      )}
    </StyledCard>
  );
}
