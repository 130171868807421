import styled from '@emotion/styled';
import { Alert, Button, Paper, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import LoginQuote from '../assets/login-quote.svg';
import { useAuth } from '../components/Providers/AuthProvider';
import { useIsMobile } from '../util/useIsMobile';

const MainContainer = styled('div')(() => {
  const isMobile = useIsMobile();
  return {
    height: '100vh',
    display: 'flex',
    width: '100vw',
    flexDirection: isMobile ? 'column' : 'row',
  };
});

const LoginPage = styled('div')(() => {
  const isMobile = useIsMobile();
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: isMobile ? '250px' : 'inherit',
    minWidth: !isMobile ? '50%' : '',
    padding: isMobile ? '20px' : '0',
  };
});

const LoginSection = styled(Paper)(() => {
  const isMobile = useIsMobile();
  return {
    display: 'flex',

    flexDirection: 'column',
    alignItems: 'center',
    padding: isMobile ? '20px' : '40px',
    marginLeft: isMobile ? '0' : '10px',
    marginRight: isMobile ? '0' : '10px',
    width: isMobile ? '100vw' : '360px',
    height: 'fit-content',
    '> *:not(:last-child)': {
      marginBottom: isMobile ? '10px' : '20px',
    },
    zIndex: 2,
    marginBottom: isMobile ? '0px' : '100px',
  };
});

const StyledImage = styled('img')(() => {
  const isMobile = useIsMobile();
  return {
    height: isMobile ? 'auto' : '700px',
    width: isMobile ? '100vw' : 'auto',
  };
});

const ImageContainer = styled('div')(() => {
  const isMobile = useIsMobile();
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'white',
    maxWidth: '100%',
    overflowX: 'hidden',
    overflowY: 'hidden',
    minWidth: isMobile ? '100%' : '50%',
  };
});

const DisclaimerAlert = styled(Alert)(() => {
  const isMobile = useIsMobile();
  return {
    backgroundColor: '#D3EAFF',
    position: 'absolute',
    bottom: isMobile ? '10px' : '32px',
    left: isMobile ? '10px' : '20px',
    right: isMobile ? '10px' : 'auto',
    width: isMobile ? '88vw' : 'auto',
  };
});

const Login = (): JSX.Element => {
  const navigate = useNavigate();
  // use the auth context to get the login function
  const { currentUser, authenticateAzureAD } = useAuth();
  // const analytics = useAnalytics();

  useEffect(() => {
    if (currentUser) {
      navigate('/');
      // analytics.identify();
    }
  }, [currentUser, navigate]); //, analytics, analytics.identify]);

  const ssoLogin = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const redirectTo = searchParams.get('redirect') || '/';
    authenticateAzureAD(redirectTo);
  };

  return (
    <MainContainer>
      <LoginPage>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <LoginSection>
            {/* Although we still had to keep the original properties, I included h4 
            for future reference to know that h4 is similar to what we Currently
            have in terms of fontSize */}
            <Typography variant="h4" sx={{ fontWeight: 700, fontSize: '34px' }}>
              Join the Movement.
            </Typography>
            <Button onClick={ssoLogin} variant="contained">
              Login with SSO
            </Button>
          </LoginSection>
        </div>
      </LoginPage>
      <ImageContainer>
        <StyledImage
          src={LoginQuote}
          alt="No matter what anybody tells you, words and ideas can change the world. -Robin Williams"
        />
      </ImageContainer>
      <DisclaimerAlert severity="info">This app is under active development.</DisclaimerAlert>
    </MainContainer>
  );
};

export default Login;
