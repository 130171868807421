import * as filesaver from 'file-saver';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';

import {
  fetchCategoryInfo,
  fetchProjectScopesAndCategories,
  generateReport,
  getProjectScopeCategories,
} from '../../../services/dealGPT';
import type { CategoryType, ProjectRun, ProjectType } from '../../../Types/dealGPT';
import { DealGPTView } from '../../../Types/enums';
import { useFeatures } from '../../Providers/FeatureProvider';
import DiscoveryView from '../Views/DiscoveryView';

export type DiscoveryProps = {
  project: ProjectType;
  selectedView: DealGPTView;
  isLoadingRescan: boolean;
  openFilterDialog: boolean;
  isLoadingScopesDialog: boolean;
  isScopePreferenceSelected: (scope: string) => boolean;
  toggleFilter: (scope: string) => void;
  toggleSelectAllScopeFilter: () => void;
  handleRescanDocuments: (projectId: string) => void;
  handleOpenFilterDialog: () => void;
  handleCloseFilterDialog: () => void;
  setSelectedRun: (run: ProjectRun | undefined) => void;
  timer: string | null;
  projectRuns: ProjectRun[] | undefined;
  selectedRun?: ProjectRun;
  projectRunStatus: string;
  distinctScopes: string[];
  numScopeAreasSelected: number;
  isSelectAllFilter: boolean;
};

const Discovery = ({
  project,
  selectedView,
  isLoadingRescan,
  openFilterDialog,
  isLoadingScopesDialog,
  isScopePreferenceSelected,
  toggleFilter,
  toggleSelectAllScopeFilter,
  handleRescanDocuments,
  handleOpenFilterDialog,
  handleCloseFilterDialog,
  setSelectedRun,
  selectedRun,
  timer,
  projectRuns,
  projectRunStatus,
  distinctScopes,
  numScopeAreasSelected,
  isSelectAllFilter,
}: DiscoveryProps): JSX.Element => {
  const [selectedScopeFilters, setSelectedScopeFilters] = useState<string[]>([]);

  const [scopes, setScopes] = useState<string[]>([]);
  const [lastSelectedRun, setLastSelectedRun] = useState<ProjectRun | undefined>(selectedRun);
  const [isLoadingReport, setIsLoadingReport] = useState<boolean>(false);
  const [lastScanned, setLastScanned] = useState<string>('Scan in progress...');
  const [openRescanDialog, setOpenRescanDialog] = useState<boolean>(false);
  const [expandAllCategories, setExpandAllCategories] = useState<boolean>(false);
  const [runProgressData, setRunProgressData] = useState<CategoryType[]>([]);
  const [aggregateProgressData, setAggregateProgressData] = useState<CategoryType>({
    name: 'aggregate',
    quantity: 0,
    quantityAnswered: 0,
    quantityMoreInfoNeeded: 0,
    quantityNotFound: 0,
    quantitySkipped: 0,
    scope: '',
  });
  const [filteredRunProgressData, setFilteredRunProgressData] = useState<CategoryType[]>([]);

  const features = useFeatures();

  const handleOpenRescanDialog = () => {
    setOpenRescanDialog(true);
  };

  const handleCloseRescanDialog = () => {
    setOpenRescanDialog(false);
  };

  const handleConfirmFilterDialog = () => {
    handleRescanDocuments(project.id);
    handleCloseFilterDialog();
  };

  const handleGenerateReport = async () => {
    try {
      setIsLoadingReport(true);
      const response = await generateReport(project.id);
      filesaver.saveAs(
        new Blob([response._data ?? '']),
        `dealgpt-${project.name}-${new Date().toISOString()}.csv`
      );
    } catch (error) {
      enqueueSnackbar(`Failed to generate report: ${error}`, { variant: 'error' });
    } finally {
      setIsLoadingReport(false);
    }
  };

  const handleClearFilters = () => {
    setSelectedScopeFilters([]);
  };

  const getScope = (scope: string) => {
    return selectedScopeFilters.find((s: string) => s === scope);
  };

  const isScopeSelected = (scope: string) => {
    const selectedScope = getScope(scope);
    return !!selectedScope;
  };

  const toggleScopeFilters = (scope: string) => {
    // console.log('toggling scope', scope);
    if (selectedScopeFilters.find((s) => s === scope)) {
      setSelectedScopeFilters((prevFilters) =>
        prevFilters.filter((prevScope) => prevScope !== scope)
      );
    } else {
      setSelectedScopeFilters([...selectedScopeFilters, scope]);
    }
  };

  const toggleCollapseExpandAllCategories = () => {
    setExpandAllCategories(!expandAllCategories);
  };

  const aggregateCategoryData = (categoriesInfo: CategoryType[]) => {
    const aggregateInfo: CategoryType = {
      name: 'aggregate',
      quantity: 0,
      quantityAnswered: 0,
      quantityMoreInfoNeeded: 0,
      quantityNotFound: 0,
      quantitySkipped: 0,
      scope: '',
    };

    categoriesInfo.forEach((category) => {
      aggregateInfo.quantity += category.quantity;
      if (
        aggregateInfo.quantityAnswered !== undefined &&
        aggregateInfo.quantityMoreInfoNeeded !== undefined &&
        aggregateInfo.quantityNotFound !== undefined &&
        aggregateInfo.quantitySkipped !== undefined &&
        category.quantityAnswered !== undefined &&
        category.quantityMoreInfoNeeded !== undefined &&
        category.quantityNotFound !== undefined &&
        category.quantitySkipped !== undefined
      ) {
        aggregateInfo.quantityAnswered += category.quantityAnswered;
        aggregateInfo.quantityMoreInfoNeeded += category.quantityMoreInfoNeeded;
        aggregateInfo.quantityNotFound += category.quantityNotFound;
        aggregateInfo.quantitySkipped += category.quantitySkipped;
      }
    });

    return aggregateInfo;
  };

  useEffect(() => {
    // Fetch question scope and categories
    let categories: CategoryType[] = [];
    const fetchQuestionScopeAndCategories = async () => {
      if (selectedRun) {
        console.log('running once');
        console.log(selectedRun);

        const result = await fetchProjectScopesAndCategories(project.id, selectedRun.id);
        if (result._isSuccess && result._data) {
          // extract scopes into a set
          const scopesArray = result._data.map((scopeAndCategory) => scopeAndCategory.scope);
          const scopesSet = Array.from(new Set(scopesArray));

          const categoriesArray = result._data.map((scopeAndCategory) => ({
            name: scopeAndCategory.category,
            scope: scopeAndCategory.scope,
            quantity: scopeAndCategory.quantityOfQuestions,
            progress: scopeAndCategory.progress,
          }));

          setScopes(scopesSet);
          categories = categoriesArray;
        } else if (result._isSuccess && result._data?.length === 0) {
          setScopes([]);
          setRunProgressData([]);
          setAggregateProgressData({
            name: 'aggregate',
            quantity: 0,
            quantityAnswered: 0,
            quantityMoreInfoNeeded: 0,
            quantityNotFound: 0,
            quantitySkipped: 0,
            scope: '',
          });

          enqueueSnackbar('No questions for selected run', { variant: 'info' });
        }

        const filterRunProgressData = async () => {
          const runProgress: CategoryType[] = [];
          try {
            for (const category of categories) {
              const result = await fetchCategoryInfo(
                project.id,
                category.scope,
                category.name,
                selectedRun.id
              );

              if (result._isSuccess && result._data) {
                const progressInfo = result._data;
                runProgress.push(progressInfo);
              } else {
                enqueueSnackbar(`Failed to fetch run progress data: ${result._error}`, {
                  variant: 'error',
                });
              }
            }
          } catch (error) {
            enqueueSnackbar(`Failed to fetch run progress data: ${error}`, { variant: 'error' });
            // setIsLoadingRunData(false);
          }

          console.log('run progress', runProgress);

          setRunProgressData(runProgress);
          setAggregateProgressData(aggregateCategoryData(runProgress));
        };
        await filterRunProgressData();
        setLastSelectedRun(selectedRun);
      }
    };

    // TODO: hotfix, make sure this doesn't execute if it doesn't have to (i.e. when project.projectRunStatus is not 'running' or 'completed')
    // but we have to make sure it runs once to load questions on initial page load
    fetchQuestionScopeAndCategories();
  }, [
    project.id,
    project.lastScanned,
    timer,
    project.projectRunStatus,
    selectedRun,
    lastSelectedRun,
    runProgressData.length,
  ]);

  useEffect(() => {
    // Filter categories based on selectedScopeFilters
    const filterCategories = () => {
      let filtered: CategoryType[] = [];

      // console.log(
      //   'selectedRun',
      //   selectedRun,
      //   'selectedScopeFilters',
      //   selectedScopeFilters,
      //   'runProgressData',
      //   runProgressData
      // );

      if (selectedRun) {
        if (selectedScopeFilters.length > 0) {
          selectedScopeFilters.forEach(async (scope) => {
            const result = await getProjectScopeCategories(project.id, scope, selectedRun.id);
            if (result._isSuccess && result._data) {
              const scopeAndCategories = result._data;

              scopeAndCategories.forEach((scopeAndCategory) => {
                const category = runProgressData.find(
                  (category) =>
                    category.scope === scopeAndCategory.scope &&
                    category.name === scopeAndCategory.category
                );

                if (category) {
                  filtered = [...filtered, category];
                } else {
                  enqueueSnackbar(
                    `Category information cannot be found for ${scopeAndCategory.category}`
                  );
                }
              });

              setFilteredRunProgressData(filtered);
            }
          });
        } else {
          console.log('setting default categories', runProgressData);

          setFilteredRunProgressData(runProgressData);
        }
      }
    };
    filterCategories();
  }, [runProgressData, project.id, selectedRun, selectedScopeFilters]);

  useEffect(() => {
    if (project.lastScanned) {
      setLastScanned(new Date(project.lastScanned).toLocaleString());
    } else {
      setLastScanned(
        'Never scanned, please reach out to the Intellio® Advantage team to kick off a scan.'
      );
    }
  }, [handleRescanDocuments, project.id, project.lastScanned]);

  return (
    <DiscoveryView
      features={features}
      project={project}
      scopes={scopes}
      distinctScopes={distinctScopes}
      numScopeAreasSelected={numScopeAreasSelected}
      selectedView={selectedView}
      selectedRun={selectedRun}
      isLoadingRescan={isLoadingRescan}
      isLoadingReport={isLoadingReport}
      isLoadingScopesDialog={isLoadingScopesDialog}
      openFilterDialog={openFilterDialog}
      openRescanDialog={openRescanDialog}
      lastScanned={lastScanned}
      timer={timer}
      projectRuns={projectRuns}
      runProgressData={filteredRunProgressData}
      aggregateProgressData={aggregateProgressData}
      expandAllCategories={expandAllCategories}
      handleOpenFilterDialog={handleOpenFilterDialog}
      handleConfirmFilterDialog={handleConfirmFilterDialog}
      handleOpenRescanDialog={handleOpenRescanDialog}
      handleCloseRescanDialog={handleCloseRescanDialog}
      handleCloseFilterDialog={handleCloseFilterDialog}
      handleGenerateReport={handleGenerateReport}
      setSelectedRun={setSelectedRun}
      isScopeSelected={isScopeSelected}
      isScopePreferenceSelected={isScopePreferenceSelected}
      isSelectAllFilter={isSelectAllFilter}
      toggleFilter={toggleFilter}
      toggleSelectAllScopeFilter={toggleSelectAllScopeFilter}
      toggleScopeFilters={toggleScopeFilters}
      handleClearFilters={handleClearFilters}
      toggleCollapseExpandAllCategories={toggleCollapseExpandAllCategories}
      projectRunStatus={projectRunStatus ?? ''}
    />
  );
};

export default Discovery;
