import { Button, styled, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import type { ProjectScopeAreaType } from '../../../Types/dealGPT';
import { UserRole } from '../../../Types/enums';
import { useAuth } from '../../Providers/AuthProvider';
import ScopeAreaStatus from './ScopeAreaStatus';

const ScopeAreaDetailContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
  justifyContent: 'space-between',
  width: '100%',
  backgroundColor: '#ffffff',
  alignItems: 'center',
  padding: '20px 0',
});

const SecondColumnContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: '10px',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const StyledButton = styled(Button)({
  width: '100px',
  height: '35px',
});

export type ScopeAreaDetailProps = {
  scopeArea: ProjectScopeAreaType;
  projectId: string;
};

const ScopeAreaDetail = ({ scopeArea, projectId }: ScopeAreaDetailProps): JSX.Element => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const handleClick = async (id: string, scope: string, freshRun: boolean) => {
    if (freshRun) {
      navigate(`/intellio-advantage/${id}/analysis/${scope}?fresh-run=${freshRun}`);
    } else {
      navigate(`/intellio-advantage/${id}/analysis/${scope}`);
    }
  };

  if (!scopeArea) {
    return <></>;
  }

  return (
    <ScopeAreaDetailContainer key={scopeArea.scope}>
      <Typography
        sx={{ fontSize: '16px', fontWeight: '600', marginLeft: '15px', verticalAlign: 'middle' }}
      >
        {scopeArea.scope}
      </Typography>
      <SecondColumnContainer>
        <ScopeAreaStatus status={scopeArea.status} />
        <StyledButton
          variant="outlined"
          onClick={() => handleClick(projectId, scopeArea.scope, false)}
        >
          View
        </StyledButton>
        {currentUser?.roles.includes(UserRole.DEALGPT_ADMIN) && (
          <StyledButton
            variant="contained"
            onClick={() => handleClick(projectId, scopeArea.scope, true)}
          >
            New Run
          </StyledButton>
        )}
      </SecondColumnContainer>
    </ScopeAreaDetailContainer>
  );
};

export default ScopeAreaDetail;
