import { TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import { useDebounce } from '../hooks/useDebounce';

interface DebouncedSearchInputProps {
  value: string;
  valueUpdated: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  shouldFocus?: boolean;
}

const DebouncedSearchInput = ({
  value,
  valueUpdated,
  onFocus,
  onBlur,
  shouldFocus,
}: DebouncedSearchInputProps) => {
  const [localSearchQuery, setLocalSearchQuery] = useState(value);
  const debouncedSearchQuery = useDebounce(localSearchQuery, 250);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (debouncedSearchQuery === value) return;

    if (valueUpdated) {
      valueUpdated(debouncedSearchQuery);
    }
  }, [debouncedSearchQuery, value, valueUpdated, shouldFocus]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalSearchQuery(e.target.value);
  };

  useEffect(() => {
    if (inputRef.current && shouldFocus) {
      inputRef.current.focus();
    }
  }, [value, shouldFocus]);

  return (
    <TextField
      inputRef={inputRef}
      type={'search'}
      onChange={handleSearchChange}
      value={localSearchQuery}
      label={'Search'}
      autoComplete={'off'}
      fullWidth
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
};

export default DebouncedSearchInput;
