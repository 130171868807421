import { useMsal } from '@azure/msal-react';
import styled from '@emotion/styled';
import { CircularProgress } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { login, loginAzureAD, logoutUser } from '../../services/api';
import { UserRole } from '../../Types/enums';
import { User } from '../../Types/user';

interface AuthContextType {
  currentUser: User | null;
  userFullName: string;
  isAdmin: boolean;
  authenticate: (email: string, password: string, redirectTo: string) => void;
  authenticateAzureAD: (redirectTo: string) => void;
  fetchTokens: () => Promise<void>;
  fetchProfilePhoto: () => Promise<void>;
  logout: (redirect?: string) => Promise<void>;
  initialized: boolean;
}

const SpinnerContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
});

export const AuthContext = createContext<AuthContextType | null>(null);

interface Props {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [userFullName, setUserFullName] = useState('');
  const [loading, setLoading] = useState(true);
  const [initialized, setInitialized] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [checkedForToken, setCheckedForToken] = useState(false);
  const navigate = useNavigate();
  const { instance } = useMsal();

  const logout = useCallback(
    async (redirectTo?: string) => {
      try {
        sessionStorage.clear();
        localStorage.clear();
        await logoutUser();
        setCurrentUser(null);
        if (redirectTo) {
          navigate(redirectTo, { replace: true });
        }
      } catch (error) {
        setCurrentUser(null);
        enqueueSnackbar('Error logging out', { variant: 'error' });
      }
    },
    [navigate]
  );

  const fetchTokens = async () => {
    try {
      const token = await instance.acquireTokenSilent({
        scopes: [`api://${import.meta.env.VITE_AZUREAD_CLIENT_ID}/dev/app.dev`],
        account: instance.getAllAccounts()[0],
      });
      const graphToken = await instance.acquireTokenSilent({
        scopes: ['Sites.Read.All'],
        account: instance.getAllAccounts()[0],
      });
      localStorage.setItem('token', token.accessToken);
      localStorage.setItem('graphToken', graphToken.accessToken);
    } catch (error) {
      try {
        const token = await instance.acquireTokenPopup({
          scopes: [`api://${import.meta.env.VITE_AZUREAD_CLIENT_ID}/dev/app.dev`],
          account: instance.getAllAccounts()[0],
        });
        const graphToken = await instance.acquireTokenPopup({
          scopes: ['Sites.Read.All'],
          account: instance.getAllAccounts()[0],
        });
        localStorage.setItem('token', token.accessToken);
        localStorage.setItem('graphToken', graphToken.accessToken);
      } catch (popupError) {
        try {
          await instance.acquireTokenRedirect({
            scopes: [`api://${import.meta.env.VITE_AZUREAD_CLIENT_ID}/dev/app.dev`],
            account: instance.getAllAccounts()[0],
          });
          await instance.acquireTokenRedirect({
            scopes: ['Sites.Read.All'],
            account: instance.getAllAccounts()[0],
          });
        } catch (redirectError) {
          // Log the user out
          logout();
        }
      }
    }
  };

  async function fetchProfilePhoto(retryCount = 1) {
    try {
      const profilePhotoData = await fetch(
        'https://graph.microsoft.com/v1.0/me/photos/48x48/$value',
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('graphToken')}`,
          },
        }
      );

      if (profilePhotoData.status === 200) {
        const photoData = await profilePhotoData.blob();
        localStorage.setItem('profilePhoto', URL.createObjectURL(photoData));
        window.dispatchEvent(new Event('storage'));
      } else {
        throw new Error('Failed to fetch profile photo');
      }
    } catch (error) {
      if (retryCount > 0) {
        await fetchTokens();
        await fetchProfilePhoto(retryCount - 1);
      } else {
        localStorage.setItem('profilePhoto', '');
        window.dispatchEvent(new Event('storage'));
      }
    }
  }

  useEffect(() => {
    const tokenLogin = async () => {
      const searchParams = new URLSearchParams(window.location.search);
      const token = searchParams.get('token');
      if (token) {
        localStorage.setItem('jwt', token);
        setCheckedForToken(true);
      } else {
        setCheckedForToken(true);
      }
    };
    tokenLogin();
  }, []);

  useEffect(() => {
    const checkLogin = async () => {
      try {
        let user = null;
        try {
          user = await login();
        } catch (error) {
          console.error(error);
        }
        if (user) {
          setCurrentUser(user);
          setIsAdmin(user.roles.includes(UserRole.ADMIN));
        } else {
          setInitialized(false);
          await instance.initialize();
          const authResult = await instance.handleRedirectPromise();

          const token = await instance.acquireTokenSilent({
            scopes: [`api://${import.meta.env.VITE_AZUREAD_CLIENT_ID}/dev/app.dev`],
            account: instance.getAllAccounts()[0],
          });
          const graphToken = await instance.acquireTokenSilent({
            scopes: ['Sites.Read.All'],
            account: instance.getAllAccounts()[0],
          });

          localStorage.setItem('token', token.accessToken);
          localStorage.setItem('graphToken', graphToken.accessToken);

          user = await loginAzureAD();
          if (user.token) {
            localStorage.setItem('jwt', user.token);
          }
          setCurrentUser(user);
          setIsAdmin(user.roles.includes(UserRole.ADMIN));
          if (authResult?.state) {
            window.location.href = authResult.state;
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        await fetchProfilePhoto();
        setLoading(false);
        setInitialized(true);
        setUserFullName(instance.getAllAccounts()[0]?.name || '');
      }
    };
    if (checkedForToken) {
      checkLogin();
    }

    // Including the navigate dep causes an infinite loop, but using react router to navigate
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance, checkedForToken]);

  const authenticate = async (email: string, password: string, redirectTo: string) => {
    const user = await login(email, password);
    setCurrentUser(user);
    setIsAdmin(user.roles.includes(UserRole.ADMIN));
    navigate(redirectTo);
  };

  const authenticateAzureAD = async (redirectTo: string) => {
    await instance.loginRedirect({
      scopes: [`api://${import.meta.env.VITE_AZUREAD_CLIENT_ID}/dev/app.dev`],
      redirectUri: `${window.location.origin}/chat`,
      state: redirectTo,
    });
  };

  if (!initialized || loading) {
    return (
      <SpinnerContainer>
        <CircularProgress />
      </SpinnerContainer>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        userFullName,
        authenticate,
        authenticateAzureAD,
        logout,
        fetchTokens,
        fetchProfilePhoto,
        initialized,
        isAdmin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return authContext;
};

export const RequireAuth: React.FC<Props> = ({ children }) => {
  const { currentUser, initialized } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (initialized && !currentUser) {
      // Handle edge case where a user logged out while viewing a conversation, truncate the
      // url to only be chat.
      let pathname = window.location.pathname;

      if (pathname.startsWith('/chat')) {
        pathname = '/chat';
      }
      navigate(`/login?redirect=${pathname}${location.search}`, { replace: true });
    }
  }, [currentUser, initialized, navigate, location]);

  if (!initialized) {
    return <CircularProgress />;
  }

  return <>{children}</>;
};
