const TOKEN_BASE = 1000;
const TOKEN_PREFIX = 'k';
export const formatTokens = (num: number) => {
  return num < TOKEN_BASE ? `< 1${TOKEN_PREFIX}` : `${Math.round(num / TOKEN_BASE)}${TOKEN_PREFIX}`;
};

export const doubleEncodeUriComponent = (str: string) => {
  return encodeURIComponent(encodeURIComponent(str));
};

export const doubleDecodeUriComponent = (str: string) => {
  return decodeURIComponent(decodeURIComponent(str));
};
