import { Typography } from '@mui/material';
import React from 'react';
import { Placement } from 'react-joyride';

export const GuidedSteps = [
  {
    title: 'Welcome to Intellio® Advantage',
    target: '#dealgpt-welcome',
    disableBeacon: true,
    styles: {
      tooltip: {
        width: '600px',
      },
    },
    content: (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <Typography style={{ textAlign: 'start' }}>
          Welcome to Intellio® Advantage, a user-friendly internal asset powered by Nigel that
          empowers you with generative AI, enhancing your efficiency in project delivery. This tour
          will guide you through the tool&apos;s features and improvements, enabling you to harness
          its power effectively.
        </Typography>
        <Typography style={{ textAlign: 'start' }}>
          Access to project documents is securely controlled by a Teams channel membership. This
          means that only those who are part of the relevant Teams channel can access the documents.
          You&apos;re already part of a team channel that gives you access! The documents available
          are typically aligned to the scope areas, ensuring you have control over the relevant
          information. (Example: For Infrastructure, the technology documents will be scanned)
        </Typography>
      </div>
    ),
  },
  {
    title: 'Projects',
    target: '#to-be-filled-in-dynamically',
    placement: 'right' as Placement,
    disableBeacon: true,
    styles: {
      tooltip: {
        width: '600px',
      },
    },
    content: (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <Typography style={{ textAlign: 'start' }}>
          Here are your projects. You can click on a project to view the scope areas and categories
          of questions answered by scanning the documents. &apos;Scanning documents&apos; refers to
          the AI-powered process of analyzing the content of the documents to find relevant
          information. You can also view when the last scan was kicked off, how many questions were
          responded to, or where the findings were highlighted.
        </Typography>
        <Typography style={{ textAlign: 'start' }}>
          If this is a project&apos;s first load, an initial scan will begin. The percentage
          indicates the number of questions where an answer was found or if a question provides
          findings that will help you find the answer (Ex. When a status of &apos;More Info
          Needed&apos; is provided, it means that it might have found a document that may have your
          answer)
        </Typography>
      </div>
    ),
  },
  {
    title: 'Scopes',
    target: '#scopes-and-categories',
    placement: 'bottom' as Placement,
    disableBeacon: true,
    content: (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <Typography style={{ textAlign: 'start' }}>
          The questions are divided into scopes and categories.
        </Typography>
        <Typography style={{ textAlign: 'start' }}>
          Scopes are the broad categories of questions, and categories are the specific questions
          within each scope. You can filter by scope areas here to see the applicable categories and
          questions aligning with that scope.
        </Typography>
      </div>
    ),
  },
  {
    title: 'Categories',
    target: '.categories-root div:first-child',
    placement: 'bottom' as Placement,
    disableBeacon: true,
    content: (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <Typography style={{ textAlign: 'start' }}>
          This is a category. These show the number of questions in the list and the percentage of
          questions found. If a question was answered definitively, it represents a portion of the
          percentage found. Let&apos;s open it to view the questions.
        </Typography>
      </div>
    ),
  },
  {
    title: 'Questions',
    target: '#category-questions-container',
    placement: 'bottom' as Placement,
    disableBeacon: true,
    content: (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <Typography style={{ textAlign: 'start' }}>
          Here are the questions. Click on them to get more information on the summarized answer
          from the document and the link to the document.
        </Typography>
      </div>
    ),
  },
  {
    title: 'Chat',
    target: '#dealgpt-chat-button',
    placement: 'left' as Placement,
    disableBeacon: true,
    content: (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <Typography style={{ textAlign: 'start' }}>
          Lastly, you can utilize this chat feature if you have questions about the documents that
          still need to be answered. It will provide you with the document&apos;s title and a
          summary of the document as it relates to your question.
        </Typography>
      </div>
    ),
  },
];

// Old guide for GPT 4 launch (in case we want this for clients and for reference for future guides)
// export const GuidedSteps = [
//   {
//     title: 'GPT 4 is here!',
//     target: '#llm-select',
//     disableBeacon: true,
//     content: (
//       <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
//         <Typography style={{ textAlign: 'start' }}>
//           GPT 4 is here and enabled by default for everyone.
//         </Typography>
//         <ul style={{ display: 'flex', flexDirection: 'column' }}>
//           <li style={{ textAlign: 'start' }}>
//             Twice the context window of the old 3.5 model, which means you can have conversations
//             that are twice as long
//           </li>
//           <li style={{ textAlign: 'start' }}>Generally better reasoning and responses</li>
//         </ul>
//       </div>
//     ),
//   },
//   {
//     title: 'New Model Filters',
//     target: '#filter-container',
//     placement: 'right' as Placement,
//     content: (
//       <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
//         <Typography style={{ textAlign: 'start' }}>
//           New tags were added to specify the recommended model
//         </Typography>
//         <Typography style={{ textAlign: 'start' }}>
//           Some prompts will work better on GPT 3.5 or 4. Please choose the appropriate model when
//           needed.
//         </Typography>
//       </div>
//     ),
//   },
// ];
