import { Tooltip } from '@mui/material';
import React from 'react';

interface UnprocessedChangesDotProps {
  position?: { top?: string; left?: string; right?: string; bottom?: string };
}

const UnprocessedChangesDot: React.FC<UnprocessedChangesDotProps> = ({ position }) => {
  return (
    <Tooltip title="These changes have not been applied downstream" placement="top">
      <div
        style={{
          position: position ? 'absolute' : 'relative',
          width: '10px',
          height: '10px',
          background: 'orange',
          borderRadius: '5px',
          top: position?.top ? position.top : 'auto',
          left: position?.left ? position.left : 'auto',
          right: position?.right ? position.right : 'auto',
          bottom: position?.bottom ? position.bottom : 'auto',
          zIndex: '999',
        }}
      ></div>
    </Tooltip>
  );
};

export default UnprocessedChangesDot;
