import { Send, ThumbDown, ThumbDownOutlined, ThumbUp, ThumbUpOutlined } from '@mui/icons-material';
import { Chip, styled, TextField, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateFeedback } from '../../redux/actions/conversationActions';
import { selectConversations } from '../../redux/reducers/conversationReducer';
import { AppDispatch } from '../../redux/store';
import { SendButton } from '../../theme/CustomComponents';
import { Message, MessageFeedback } from '../../Types/conversation';
import { FeedbackPreselectedOptions } from '../../Types/enums';

const IconsContainer = styled('div')({
  display: 'flex',
  gap: '20px',
});

const IconContainer = styled('span')({
  color: 'gray',
  transition: 'all 0.2s ease-in-out',
  display: 'flex',
  height: '20px',
  cursor: 'pointer',

  span: {
    color: 'black',
    marginLeft: '5px',
  },
});

const ChatRow = styled('div')({
  display: 'flex',
  gap: '5px',
  position: 'sticky',
  bottom: '0',
  background: '#fffdf8',
  paddingTop: '10px',
});

const defaultMessageFeedback: MessageFeedback = {
  thumbsUp: null,
  suggestedFeedback: [],
  otherFeedback: null,
};

type ChatFeedbackProps = {
  currentMessage: Message;
};

const ChatFeedback = ({ currentMessage }: ChatFeedbackProps): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  const [messageFeedback, setMessageFeedback] = useState<MessageFeedback>(
    currentMessage.messageFeedback ?? defaultMessageFeedback
  );
  const [shouldUpdateFeedback, setShouldUpdateFeedback] = useState<boolean>(false);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState<boolean>(
    (currentMessage.messageFeedback?.otherFeedback?.length ?? 0) > 0
  );
  const [otherFeedback, setOtherFeedback] = useState<string | null>(
    currentMessage.messageFeedback?.otherFeedback ?? null
  );

  const { currentConversation } = useSelector(selectConversations);

  useEffect(() => {
    setMessageFeedback(currentMessage.messageFeedback ?? defaultMessageFeedback);
    setFeedbackSubmitted((currentMessage.messageFeedback?.otherFeedback?.length ?? 0) > 0);
    setOtherFeedback(currentMessage.messageFeedback?.otherFeedback ?? null);
  }, [currentMessage]);

  useEffect(() => {
    const submitFeedback = () => {
      dispatch(updateFeedback({ messageId: currentMessage.id, messageFeedback }));
    };

    if (shouldUpdateFeedback) {
      submitFeedback();
      setShouldUpdateFeedback(false);
    }
  }, [messageFeedback, currentMessage.id, shouldUpdateFeedback, dispatch, currentConversation]);

  const handleThumbClick = (thumbsUp: boolean) => {
    if (thumbsUp !== messageFeedback?.thumbsUp ?? null) {
      setMessageFeedback({
        ...messageFeedback,
        thumbsUp,
        otherFeedback: null,
        suggestedFeedback: [],
      });
      setShouldUpdateFeedback(true);
    }
  };

  const handleChipClick = (suggestion: FeedbackPreselectedOptions) => {
    setMessageFeedback((prevMessageFeedback) => {
      if (prevMessageFeedback?.suggestedFeedback.includes(suggestion)) {
        return {
          ...prevMessageFeedback,
          suggestedFeedback: prevMessageFeedback.suggestedFeedback.filter((s) => s !== suggestion),
        };
      } else {
        return {
          ...prevMessageFeedback,
          suggestedFeedback: [...prevMessageFeedback.suggestedFeedback, suggestion],
        };
      }
    });
    setShouldUpdateFeedback(true);
  };

  if (feedbackSubmitted) {
    return (
      <div>
        <Typography sx={{ color: 'grey' }}>Thank you for your feedback!</Typography>
      </div>
    );
  }

  return (
    <div>
      <IconsContainer>
        <IconContainer onClick={() => handleThumbClick(true)}>
          {messageFeedback?.thumbsUp === true ? <ThumbUp /> : <ThumbUpOutlined />}
        </IconContainer>
        <IconContainer onClick={() => handleThumbClick(false)}>
          {messageFeedback?.thumbsUp === false ? <ThumbDown /> : <ThumbDownOutlined />}
        </IconContainer>
      </IconsContainer>
      {messageFeedback?.thumbsUp === false && (
        <div
          style={{
            margin: '20px 0px',
            padding: '20px',
            outline: '1px solid lightgrey',
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
          }}
        >
          <div>
            <Typography variant="h6" sx={{ color: 'grey' }}>
              Please tell us why
            </Typography>
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                alignContent: 'flex-start',
                gap: '10px',
                flexWrap: 'wrap',
                margin: '10px 0px 20px 0px',
              }}
            >
              {Object.values(FeedbackPreselectedOptions).map((option) => {
                if (option === FeedbackPreselectedOptions.OTHER) return null;
                return (
                  <Chip
                    variant={
                      messageFeedback?.suggestedFeedback.includes(option) ? 'filled' : 'outlined'
                    }
                    sx={{ color: 'grey', fontSize: '13px', fontWeight: '500', height: '24px' }}
                    key={option}
                    label={option}
                    onClick={() => {
                      handleChipClick(option);
                    }}
                  />
                );
              })}
            </div>
          </div>
          <div>
            <Typography variant="h6" sx={{ color: 'grey' }}>
              Or write down your thoughts here
            </Typography>
            <ChatRow>
              <div style={{ flexGrow: 1 }}>
                <TextField
                  onChange={(e) => {
                    setOtherFeedback(e.target.value);
                  }}
                  variant="outlined"
                  InputProps={{
                    inputProps: {
                      style: { fontSize: '14px' },
                    },
                  }}
                  multiline
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  autoFocus
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                      e.preventDefault();
                      setMessageFeedback({ ...messageFeedback, otherFeedback });
                      setShouldUpdateFeedback(true);
                      setFeedbackSubmitted(true);
                    }
                  }}
                  autoComplete="off"
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  height: '100%',
                  boxSizing: 'border-box',
                }}
              >
                <Tooltip title={<Typography variant="caption"> Send </Typography>}>
                  <span>
                    <SendButton
                      onClick={() => {
                        setMessageFeedback({ ...messageFeedback, otherFeedback });
                        setShouldUpdateFeedback(true);
                        setFeedbackSubmitted(true);
                      }}
                      color="primary"
                    >
                      <Send
                        sx={
                          messageFeedback.suggestedFeedback.length > 0 ||
                          (otherFeedback?.length ?? 0) > 0
                            ? { color: 'blue' }
                            : { color: 'grey' }
                        }
                      />
                    </SendButton>
                  </span>
                </Tooltip>
              </div>
            </ChatRow>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatFeedback;
