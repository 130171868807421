import { Typography } from '@mui/material';
import React, { useState } from 'react';

interface ReadMoreTextProps {
  text: string;
  maxLength: number;
}

const ReadMoreText: React.FC<ReadMoreTextProps> = ({ text, maxLength }) => {
  const [isTextShown, setIsTextShown] = useState(false);

  const toggleIsTextShown = () => {
    setIsTextShown(!isTextShown);
  };

  const previewText = text.substring(0, maxLength);
  const lastBreakIndex = Math.max(previewText.lastIndexOf(' '), previewText.lastIndexOf('\n'));
  const remainingText = text.substring(lastBreakIndex + 1);

  return (
    <div>
      <Typography style={{ whiteSpace: 'pre-line' }}>
        <span>{`${previewText.substring(0, lastBreakIndex)}${!isTextShown ? '... ' : ' '}`}</span>
        {!isTextShown && (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              toggleIsTextShown();
            }}
            style={{
              color: '#757575',
              textDecoration: 'underline',
              verticalAlign: 'baseline',
              fontSize: '0.90rem',
              fontStyle: 'italic',
            }}
          >
            Read More
          </a>
        )}
        {isTextShown && (
          <span
            style={{
              transition: 'opacity 0.5s',
              opacity: isTextShown ? 1 : 0,
            }}
          >
            {remainingText}&nbsp;
            <a
              href="#"
              onClick={toggleIsTextShown}
              style={{
                color: '#757575',
                textDecoration: 'underline',
                verticalAlign: 'baseline',
                fontSize: '0.90rem',
                fontStyle: 'italic',
              }}
            >
              Read Less
            </a>
          </span>
        )}
      </Typography>
    </div>
  );
};

export default ReadMoreText;
