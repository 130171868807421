/* eslint-disable prettier/prettier */
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { NavBarMode, PageRoute } from '../../components/NavBar';
import { useAuth } from './AuthProvider';
import { useFeatures } from './FeatureProvider';

const defaultNigelRoutes: PageRoute[] = [
  {
    name: 'Chat',
    route: '/chat',
  },
  {
    name: 'Prompts',
    route: '/prompts',
  },
  {
    name: 'My Prompts',
    route: '/my-prompts',
  },
  {
    name: 'FAQ',
    route: 'https://howto.westmonroe.com/a/1862188-education-available-for-nigel',
    external: true
  },
];

const defaultDealGPTRoutes: PageRoute[] = [
  {
    name: 'HOW-TO',
    route: 'https://howto.westmonroe.com/m/120449',
    external: true,
  },
];

interface NavBarRoutesContextType {
  routes: PageRoute[];
  setAppMode: (mode: NavBarMode) => void;
}

export const NavBarRoutesContext = createContext<NavBarRoutesContextType | null>(null);

interface Props {
  children: React.ReactNode;
}

export const NavBarRoutesProvider: React.FC<Props> = ({ children }) => {
  const { isAdmin } = useAuth();
  const features = useFeatures();
  const [routes, setRoutes] = useState<PageRoute[]>(defaultNigelRoutes);
  const [currentAppMode, setCurrentAppMode] = useState<NavBarMode>(NavBarMode.NIGEL);

  useEffect(() => {
    if (currentAppMode === NavBarMode.NIGEL) {
      setRoutes(defaultNigelRoutes);
    } else if (currentAppMode === NavBarMode.DEALGPT) {
      setRoutes(defaultDealGPTRoutes);
    } else if (currentAppMode === NavBarMode.BLANK) {
      setRoutes([]);
    }
  }, [isAdmin, features.stats_page_access, currentAppMode]);

  const setAppMode = useCallback((mode: NavBarMode) => {
    setCurrentAppMode(mode);
  }, []);

  return (
    <NavBarRoutesContext.Provider value={{ routes, setAppMode }}>
      {children}
    </NavBarRoutesContext.Provider>
  );
};

export const useNavBarRoutes = (): NavBarRoutesContextType => {
  const navBarRoutesContext = useContext(NavBarRoutesContext);

  if (!navBarRoutesContext) {
    throw new Error('useNavBarRoutes must be used within a NavBarRoutesProvider');
  }

  return navBarRoutesContext;
};
