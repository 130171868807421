import CloseIcon from '@mui/icons-material/Close';
import { Button, CircularProgress, Container, styled, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import supersub from 'remark-supersub';

import type { QuestionType } from '../../Types/dealGPT';
import QuestionStatus from './QuestionStatus';

const QuestionViewContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  gap: '29px',
  margin: '40px auto',
  padding: '24px',
  paddingBottom: '60px',
  // backgroundColor: '#ffffff',
  // boxShadow: '0px 2px 8px 0px #0000001A',
  // width: '450px',
});

const SourceLink = styled('a')({
  color: '#1979CD',
  textDecoration: 'none',

  '&:visited': {
    color: '#800080',
  },
});

const ReactMarkdownWrapper = styled('div')({
  fontSize: '14px',
  lineHeight: '1.5rem',
  letterSpacing: '0.25px',
  '& > *': {
    fontFamily: 'Noto Sans',
  },

  'a, a:visited, a:hover, a:focus': {
    color: 'inherit',
  },
});

export type QuestionViewProps = {
  questions: QuestionType[];
  question: QuestionType;
  handleCloseQuestion: () => void;
};

const QuestionView = ({
  questions,
  question,
  handleCloseQuestion,
}: QuestionViewProps): JSX.Element => {
  const [questionObject, setQuestionObject] = useState<QuestionType>();

  useEffect(() => {
    if (question) {
      const questionObject = questions.find((q) => q.question === question.question);

      if (questionObject) {
        setQuestionObject(questionObject);
      }
    }
  }, [question, questions]);

  if (!questionObject) {
    return <CircularProgress sx={{ marginTop: '80px' }} />;
  }

  return (
    <QuestionViewContainer maxWidth="sm">
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h2" sx={{ fontSize: '16px', fontWeight: '600' }}>
          {questionObject.question}
        </Typography>
        <Button onClick={handleCloseQuestion} sx={{ padding: 0, minWidth: 'unset' }}>
          <CloseIcon sx={{ color: '#092B49' }} />
        </Button>
      </div>
      <div>
        <QuestionStatus status={questionObject.status} />
      </div>
      <div>
        <Typography sx={{ fontWeight: '700', marginBottom: '8px' }}>Response</Typography>
        <Typography sx={{ fontWeight: '400' }}>{questionObject.response}</Typography>
      </div>
      <div>
        <Typography sx={{ fontWeight: '700', marginBottom: '8px' }}>Findings</Typography>
        <ReactMarkdownWrapper>
          <ReactMarkdown remarkPlugins={[remarkGfm, supersub]}>
            {questionObject.explanation || ''}
          </ReactMarkdown>
        </ReactMarkdownWrapper>
      </div>
      <div>
        <Typography sx={{ fontWeight: '700', marginBottom: '8px' }}>Source(s)</Typography>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
          {questionObject.documents?.map((document) => (
            <SourceLink
              key={document.gleanId}
              href={document.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Typography>{document.fileName}</Typography>
            </SourceLink>
          ))}
        </div>
      </div>
    </QuestionViewContainer>
  );
};

export default QuestionView;
