const DEFAULT_VALUES = {
  VITE_ENVIRONMENT: 'local',
};

const environment = {
  ...DEFAULT_VALUES,
  ...import.meta.env,
};

export default environment;
