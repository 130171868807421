import { PayloadAction } from '@reduxjs/toolkit';
import * as filesaver from 'file-saver';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

import {
  deleteConversation,
  downloadFile,
  updateConversation,
} from '../../redux/actions/conversationActions';
import { Conversation, DownloadConversationResponse } from '../../Types/conversation';
import { AppEnum, AppNameBannerMap } from '../../Types/enums';
import DrawerCard from '../Drawer/DrawerCard';

type ChatCardProps = {
  conversation: Conversation;
  onConversationCardClick: (
    conversationId?: string,
    projectId?: string,
    shouldFetchConversations?: boolean
  ) => void;
};

const ChatCard = ({ onConversationCardClick, conversation }: ChatCardProps) => {
  // const [appLabel, setAppLabel] = useState(

  // );
  const dispatch = useDispatch<AppDispatch>();

  const handleConversationDelete = (conversationId: string) => {
    dispatch(deleteConversation(conversationId)).then(() => {
      onConversationCardClick(undefined, conversation.projectId);
    });
  };

  const handleConversationDownload = (conversation: Conversation) => {
    dispatch(downloadFile(conversation.id)).then(
      (action: PayloadAction<DownloadConversationResponse | unknown>) => {
        const response = action.payload as DownloadConversationResponse;
        filesaver.saveAs(
          new Blob([response.conversation]),
          `${
            conversation.title.length > 50
              ? conversation.title.slice(0, 50).concat('...')
              : conversation.title
          }.md`
        );
      }
    );
  };

  const handleConversationShare = (conversationId: string, newValue: boolean) => {
    dispatch(updateConversation({ updatedFields: { shared: newValue }, conversationId }));

    const sharingMessage = newValue ? 'shared' : 'unshared';
    enqueueSnackbar(`Conversation ${sharingMessage}`, { variant: 'success' });
  };

  return (
    <DrawerCard
      title={
        conversation.title.length > 50
          ? conversation.title.slice(0, 50).concat('...')
          : conversation.title
      }
      id={conversation.id}
      time={conversation.createdAt}
      appName={
        conversation.appName !== AppEnum.DEAL_GPT
          ? AppNameBannerMap[conversation.appName]
          : (conversation.projectName as string)
      }
      selected={window.location.pathname.includes(conversation.id)}
      hasFileUploaded={conversation.fileUpload === true}
      shared={conversation.shared}
      canBeShared={conversation.appName !== AppEnum.DEAL_GPT}
      type="conversation"
      onDrawerCardClick={() =>
        onConversationCardClick(conversation.id, conversation.projectId, false)
      }
      onDrawerCardUpdate={() => onConversationCardClick(conversation.id, conversation.projectId)}
      onDrawerCardRename={(newTitle: string) =>
        dispatch(
          updateConversation({
            updatedFields: { title: newTitle },
            conversationId: conversation.id,
          })
        ).then(() => {
          onConversationCardClick(conversation.id, conversation.projectId);
        })
      }
      onDrawerCardDelete={() => handleConversationDelete(conversation.id)}
      handleDrawerCardDownload={() => handleConversationDownload(conversation)}
      onDrawerCardShareUpdate={(newValue) => handleConversationShare(conversation.id, newValue)}
    />
  );
};

export default ChatCard;
