import { AutoMode } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Box,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ChatStatsIcon from '../../assets/chat-stats-icon.svg';
import DefaultUserStatsIcon from '../../assets/default-user-stats-icon.svg';
import MessagesStatsIcon from '../../assets/messages-stats-icon.svg';
import { selectAppStatus } from '../../redux/reducers/appStatusReducer';
import { getHomePageStats } from '../../services/stats';
import { Conversation } from '../../Types/conversation';
import { AppEnum } from '../../Types/enums';
import { Prompt } from '../../Types/prompt';
import { HomePageStats } from '../../Types/stats';
import { useIsMobile } from '../../util/useIsMobile';

const TitleBox = styled('div')(({ theme }) => {
  const isMobile = useIsMobile();
  return {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'start',
    marginBottom: '30px',
    marginTop: '30px',
    width: isMobile ? '92vw' : '70%',
    alignItems: 'flex-start',
    color: theme.palette.utility.text.primary,
  };
});

const InfoCard = styled(Box)(({ theme }) => {
  const isMobile = useIsMobile();
  return {
    width: isMobile ? '92vw' : '280px',
    minHeight: isMobile ? '100px' : '305px',
    textAlign: 'start',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: isMobile ? '20px' : '0',
    marginLeft: isMobile ? '-15px' : '0',
    h4: {
      marginLeft: isMobile ? '0' : '20px',
      marginTop: '30px',
      marginBottom: '20px',
      textAlign: isMobile ? 'center' : 'start',
      color: theme.palette.utility.text.primary,
    },
  };
});

const Link = styled('span')(({ theme }) => ({
  textDecoration: 'underline',
  color: theme.palette.utility.text.link,
  ':hover': {
    cursor: 'pointer',
  },
}));

const StatsContainer = styled('div')(() => {
  const isMobile = useIsMobile();
  return {
    width: '100%',
    justifyContent: 'flex-start',
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    alignItems: isMobile ? 'center' : 'flex-start',
  };
});

const StyledStatsIcon = styled('img')(() => {
  const isMobile = useIsMobile();
  return {
    width: '60px',
    marginRight: isMobile ? '0' : '20px',
    marginBottom: isMobile ? '10px' : '0',
  };
});

interface CustomAccordionProps extends AccordionProps {
  backgroundColor?: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CustomAccordion = styled(({ backgroundColor, ...props }: CustomAccordionProps) => (
  <Accordion {...props} />
))(({ backgroundColor }) => ({
  marginBottom: '10px',
  '& .MuiAccordionSummary-root': {
    backgroundColor: backgroundColor || 'defaultBackgroundColor',
  },
  '& .MuiAccordionDetails-root': {
    backgroundColor: backgroundColor || 'defaultBackgroundColor',
    paddingLeft: '10px',
  },
}));

export type ChatInfoProps = {
  currentConversation?: Conversation;
  currentPrompt?: Prompt;
  shouldShowInfo: boolean;
  showBanners: boolean;
};

const ChatInfo = ({
  currentConversation,
  currentPrompt,
  shouldShowInfo,
  showBanners,
}: ChatInfoProps) => {
  const theme = useTheme();
  const [stats, setStats] = useState<HomePageStats>({
    numUsers: 0,
    numActiveConversations: 0,
    numMessages: 0,
  });
  const { appName, selectedApp } = useSelector(selectAppStatus);
  const [shouldFetchStats, setShouldFetchStats] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStats = async () => {
      const newStats = await getHomePageStats();
      setStats(newStats);
      setShouldFetchStats(false);
    };
    if (shouldFetchStats) {
      fetchStats();
    }
  }, [shouldFetchStats]);

  const isMobile = useIsMobile();

  return (
    <div>
      {currentPrompt?.userEditRequired === false ||
      (currentConversation && currentConversation.prompt?.userEditRequired === false) ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div style={{ width: '70%' }}>
            <AutoMode sx={{ width: '36px', height: '36px', marginY: '30px' }} />
            <Typography
              variant="h4"
              sx={{ marginTop: '-20px', marginBottom: '30px', fontWeight: 700 }}
            >
              {currentPrompt
                ? currentPrompt.title
                : currentConversation && currentConversation.prompt?.title}
            </Typography>
            <Typography sx={{ fontSize: '20px', marginBottom: '30px' }}>
              {currentPrompt
                ? currentPrompt.description
                : currentConversation && currentConversation.prompt?.description}
            </Typography>
          </div>
        </div>
      ) : shouldShowInfo ? (
        <div>
          <TitleBox>
            <Typography variant="h3" sx={{ marginBottom: '10px' }}>
              {`Welcome to ${appName}`}
            </Typography>
            {selectedApp === AppEnum.DALLE ? (
              <Typography variant="subtitle1" sx={{ fontSize: '16px' }}>
                DALL-E is an AI image generator that transforms your text prompts into stunning
                visual art. Whether you need creative illustrations, realistic photos, or abstract
                designs, DALL-E brings your ideas to life. Explore the endless possibilities of
                AI-generated images with DALL-E!
              </Typography>
            ) : (
              <Typography variant="body1">
                {appName} is a conversational AI chatbot you can use to generate ideas, <br />
                create draft presentations, or just brainstorm. Happy chatting!
              </Typography>
            )}
          </TitleBox>

          {showBanners &&
            (isMobile ? (
              <div style={{ width: '92vw' }}>
                <CustomAccordion backgroundColor="#d3eaff">
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6" sx={{ color: '#062541', fontWeight: '700' }}>
                      Stats
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <InfoCard>
                      <div
                        style={{
                          flexDirection: isMobile ? 'row' : 'column',
                          justifyContent: 'space-between',
                          margin: '0 20px 20px 20px',
                          gap: '30px',
                          display: 'flex',
                        }}
                      >
                        <StatsContainer>
                          <StyledStatsIcon src={DefaultUserStatsIcon} />
                          <Typography>
                            <span style={{ fontWeight: 'bold' }}>{stats.numUsers}</span> Users
                          </Typography>
                        </StatsContainer>
                        <StatsContainer>
                          <StyledStatsIcon src={ChatStatsIcon} />
                          <Typography>
                            <span style={{ fontWeight: 'bold' }}>
                              {stats.numActiveConversations}
                            </span>{' '}
                            Chats Today
                          </Typography>
                        </StatsContainer>
                        <StatsContainer>
                          <StyledStatsIcon src={MessagesStatsIcon} />
                          <Typography>
                            <span style={{ fontWeight: 'bold' }}>{stats.numMessages}</span> Messages
                          </Typography>
                        </StatsContainer>
                      </div>
                    </InfoCard>
                  </AccordionDetails>
                </CustomAccordion>

                <CustomAccordion backgroundColor="#fae1f1">
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6" sx={{ color: '#062541', fontWeight: '700' }}>
                      Prompts
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <InfoCard>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'flex-start',
                          justifyContent: 'center',
                          margin: '0 20px 20px 20px',
                          flexGrow: 1,
                        }}
                      >
                        <Typography>
                          Be sure to check out the prompt library, a.k.a.{' '}
                          <Link onClick={() => navigate('/prompts')}>Prompts</Link>
                          . <br />
                          <br />
                          Think of them as little “head starts” that can help you quickly get the
                          info you want.
                        </Typography>
                      </div>
                    </InfoCard>
                  </AccordionDetails>
                </CustomAccordion>

                <CustomAccordion backgroundColor="#ffe9ca">
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6" sx={{ color: '#062541', fontWeight: '700' }}>
                      NEW 🎉 GPT 4o
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <InfoCard>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'flex-start',
                          justifyContent: 'center',
                          margin: '0 20px 20px 20px',
                          flexGrow: 1,
                        }}
                      >
                        <Typography>
                          GPT 4o, OpenAI&apos;s latest model, is now the default in Nigel. <br />
                          <br />
                          It&apos;s faster, more accurate, better at reasoning, and has a larger
                          context window.
                        </Typography>
                      </div>
                    </InfoCard>
                  </AccordionDetails>
                </CustomAccordion>
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  gap: '10px',
                  marginBottom: '20px',
                  flexWrap: 'nowrap',
                }}
              >
                <InfoCard sx={{ bgcolor: '#d3eaff', '&:hover': { cursor: 'default' } }}>
                  <Typography
                    variant="h4"
                    sx={{
                      color: theme.palette.utility.text.primary,
                      fontWeight: '700',
                      fontSize: '24px',
                    }}
                  >
                    Stats
                  </Typography>
                  <div
                    style={{
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      margin: '0 20px 20px 20px',
                      gap: '30px',
                      display: 'flex',
                    }}
                  >
                    <StatsContainer>
                      <StyledStatsIcon src={DefaultUserStatsIcon} />
                      <Typography>
                        <span style={{ fontWeight: 'bold' }}>{stats.numUsers}</span> Users
                      </Typography>
                    </StatsContainer>
                    <StatsContainer>
                      <StyledStatsIcon src={ChatStatsIcon} />
                      <Typography>
                        <span style={{ fontWeight: 'bold' }}>{stats.numActiveConversations}</span>{' '}
                        Chats Today
                      </Typography>
                    </StatsContainer>
                    <StatsContainer>
                      <StyledStatsIcon src={MessagesStatsIcon} />
                      <Typography>
                        <span style={{ fontWeight: 'bold' }}>{stats.numMessages}</span> Messages
                      </Typography>
                    </StatsContainer>
                  </div>
                </InfoCard>
                <InfoCard sx={{ bgcolor: '#fae1f1' }}>
                  <Typography
                    variant="h4"
                    sx={{
                      color: theme.palette.utility.text.primary,
                      fontWeight: '700',
                      fontSize: '24px',
                    }}
                  >
                    Prompts
                  </Typography>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      justifyContent: 'center',
                      margin: '0 20px 20px 20px',
                      flexGrow: 1,
                    }}
                  >
                    <Typography>
                      Be sure to check out the prompt library, a.k.a.{' '}
                      <Link onClick={() => navigate('/prompts')}>Prompts</Link>
                      . <br />
                      <br />
                      Think of them as little “head starts” that can help you quickly get the info
                      you want.
                    </Typography>
                  </div>
                </InfoCard>
                <InfoCard sx={{ bgcolor: '#ffe9ca' }}>
                  <Typography
                    variant="h4"
                    sx={{
                      color: theme.palette.utility.text.primary,
                      fontWeight: '700',
                      fontSize: '24px',
                    }}
                  >
                    NEW 🎉 GPT 4o
                  </Typography>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      justifyContent: 'center',
                      margin: '0 20px 20px 20px',
                      flexGrow: 1,
                    }}
                  >
                    <Typography>
                      GPT 4o, OpenAI&apos;s latest model, is now the default in Nigel. <br />
                      <br />
                      It&apos;s faster, more accurate, better at reasoning, and has a larger context
                      window.
                    </Typography>
                  </div>
                </InfoCard>
              </div>
            ))}
        </div>
      ) : null}
    </div>
  );
};

export default ChatInfo;
