import styled from '@emotion/styled';
import { MenuOpen } from '@mui/icons-material';
import { Fab, Tooltip, Typography } from '@mui/material';
import React from 'react';

import { useIsMobile } from '../../util/useIsMobile';

const CloseIconContainer = styled('span')(() => {
  const isMobile = useIsMobile();
  return {
    color: 'gray',
    transition: 'all 0.2s ease-in-out',
    display: 'flex',
    marginTop: '5px',
    paddingTop: isMobile ? '46px' : '64px',
    span: {
      color: 'black',
      marginLeft: '5px',
    },
  };
});

const OpenIconContainer = styled('span')(() => {
  const isMobile = useIsMobile();
  return {
    color: 'gray',
    transition: 'all 0.2s ease-in-out',
    display: 'flex',
    position: 'absolute',
    top: '5px',
    paddingTop: isMobile ? '46px' : '64px',
    span: {
      color: 'black',
      marginLeft: '5px',
    },
  };
});

const DrawerToggleTooltip = styled(Tooltip)({
  boxShadow:
    '0px 3px 5px -1px rgba(0, 0, 0, 0.20), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)',
});

const DrawerToggleFab = styled(Fab)({
  backgroundColor: 'white',
  position: 'absolute',
  marginLeft: '10px',
  marginTop: '10px',
  height: '30px',
  width: '30px',
  minHeight: '0',
  boxShadow: 'none',
});

const DrawerToggleButton = ({
  isDrawerOpen,
  setIsDrawerOpen,
  tooltipCaption,
}: {
  isDrawerOpen: boolean;
  setIsDrawerOpen: (newValue: boolean) => void;
  tooltipCaption: string;
}) => {
  return isDrawerOpen ? (
    <CloseIconContainer>
      <DrawerToggleTooltip
        key={'open'}
        title={<Typography variant="caption">{tooltipCaption}</Typography>}
        id={'drawer-action-label-open'}
        PopperProps={{ keepMounted: true }}
      >
        <DrawerToggleFab
          aria-labelledby={'drawer-action-label-open'}
          tabIndex={1}
          onClick={() => setIsDrawerOpen(false)}
        >
          <MenuOpen sx={{ height: '20px', width: '20px' }} />
        </DrawerToggleFab>
      </DrawerToggleTooltip>
    </CloseIconContainer>
  ) : (
    <OpenIconContainer>
      <DrawerToggleTooltip
        key="close"
        title={<Typography variant="caption">{tooltipCaption}</Typography>}
        id={'drawer-action-label-close'}
        PopperProps={{ keepMounted: true }}
      >
        <DrawerToggleFab
          tabIndex={1}
          onClick={() => setIsDrawerOpen(true)}
          size="small"
          aria-labelledby={'drawer-action-label-close'}
        >
          <MenuOpen sx={{ transform: 'rotate(180deg)', height: '20px', width: '20px' }} />
        </DrawerToggleFab>
      </DrawerToggleTooltip>
    </OpenIconContainer>
  );
};

export default DrawerToggleButton;
