import React from 'react';

import HomePageInfo from '../../components/DealGPT/HomePageInfo';
import DealGPTContainer from '../../components/DealGPT/ViewModels/DealGPTContainer';

const DealGPT = (): JSX.Element => {
  return (
    <DealGPTContainer>
      <div style={{ overflow: 'auto', width: '100%', height: '100%' }}>
        <HomePageInfo />
      </div>
    </DealGPTContainer>
  );
};

export default DealGPT;
