import { CircularProgress, styled, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';

import { getUserStats } from '../../services/stats';
import { UserStat } from '../../Types/stats';
import CustomDataGridToolbar from '../UserStats/CustomDataGridToolbar';

const UserStatsDiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginTop: '20px',
});

const formatNumber = (num: number) => {
  if (Math.abs(num) >= 1.0e9) {
    return (Math.abs(num) / 1.0e9).toFixed(1) + 'B';
  } else if (Math.abs(num) >= 1.0e6) {
    return (Math.abs(num) / 1.0e6).toFixed(1) + 'M';
  } else if (Math.abs(num) >= 1.0e3) {
    return (Math.abs(num) / 1.0e3).toFixed(1) + 'K';
  } else {
    return num.toString();
  }
};

const columns: GridColDef[] = [
  {
    field: 'email',
    headerName: 'Email',
    width: 250,
    renderHeader: () => <strong>Email</strong>,
  },
  {
    field: 'messages_sent_and_received',
    type: 'number',
    headerName: 'Messages Sent & Received',
    width: 200,
    renderHeader: () => <strong>Messages Sent & Received</strong>,
    renderCell: (params) => formatNumber(params.value),
  },
  {
    field: 'token_usage',
    headerName: 'Token Usage',
    type: 'number',
    width: 100,
    renderHeader: () => <strong>Token Usage</strong>,
    renderCell: (params) => formatNumber(params.value),
  },
  {
    field: 'tokens_per_message',
    headerName: 'Tokens/Message',
    type: 'number',
    width: 200,
    renderHeader: () => <strong>Tokens/Message</strong>,
    renderCell: (params) => formatNumber(params.value),
  },
];

const UserStatsPage = (): JSX.Element => {
  const [loading, setLoading] = useState(true);
  const [userStats, setUserStats] = useState<UserStat[]>([]);
  const [shouldFetch, setShouldFetch] = useState(true);

  useEffect(() => {
    const fetchStats = async () => {
      const newStats = await getUserStats();
      setUserStats(newStats);
      setLoading(false);
      setShouldFetch(false);
    };

    if (shouldFetch) {
      fetchStats();
    }
  }, [shouldFetch]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <UserStatsDiv>
      <Typography sx={{ fontSize: '36px', fontWeight: '700', marginBottom: '20px' }}>
        User Stats
      </Typography>
      {
        <div style={{ height: '80%', width: '100%' }}>
          <DataGrid
            slotProps={{
              panel: {
                sx: {
                  top: '-50px !important',
                },
              },
            }}
            rows={userStats}
            columns={columns}
            slots={{ toolbar: CustomDataGridToolbar }}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[10, 50, 100]}
          />
        </div>
      }
    </UserStatsDiv>
  );
};

export default UserStatsPage;
