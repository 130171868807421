import { createAsyncThunk } from '@reduxjs/toolkit';
import { enqueueSnackbar } from 'notistack';

import { API_URL, enrichedFetch } from '../../services/api';
import { Prompt } from '../../Types/prompt';
import { User } from '../../Types/user';
import { AppDispatch } from '../store';

export const updateRelation = createAsyncThunk<
  void,
  { userId: User['id']; promptId: Prompt['id']; pinned?: boolean; liked?: boolean },
  { dispatch: AppDispatch }
>(
  'userPromptRelations/updateRelation',
  async ({ userId, promptId, pinned, liked }, { rejectWithValue }) => {
    try {
      let url = `${API_URL}/user_prompt_relations?userId=${userId}&promptId=${promptId}`;

      if (pinned !== undefined) {
        url += `&pinned=${pinned}`;
      }
      if (liked !== undefined) {
        url += `&liked=${liked}`;
      }

      await enrichedFetch(url, { method: 'PUT' });
    } catch {
      enqueueSnackbar('Failed to update user prompt relation', { variant: 'error' });
      return rejectWithValue('Failed to update user prompt relation');
    }
  }
);
