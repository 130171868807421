import { combineReducers } from 'redux';

import appStatusReducer from './appStatusReducer';
import conversationReducer from './conversationReducer';
import drawerOpenReducer from './drawerOpenReducer';
import promptReducer from './promptReducer';

export default combineReducers({
  conversations: conversationReducer,
  appStatus: appStatusReducer,
  prompts: promptReducer,
  drawer: drawerOpenReducer,
});
