import { styled, Typography } from '@mui/material';
import React from 'react';

import { ScopeAreasStatus } from '../../../Types/enums';

const ScopeAreaStatusContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  borderRadius: '3px',
  padding: '3px 8px',
});

type ScopeAreaStatusProps = {
  status: ScopeAreasStatus;
};

const statusData = {
  [ScopeAreasStatus.NOT_STARTED]: {
    text: ScopeAreasStatus.NOT_STARTED,
    bgColor: '#EFF5F6',
    textColor: '#757575',
  },
  [ScopeAreasStatus.PENDING]: {
    text: ScopeAreasStatus.PENDING,
    bgColor: '#FFD4CA',
    textColor: '#E02F10',
  },
  [ScopeAreasStatus.IN_PROGRESS]: {
    text: ScopeAreasStatus.IN_PROGRESS,
    bgColor: '#D3EAFF',
    textColor: '#135996',
  },
  [ScopeAreasStatus.COMPLETE]: {
    text: ScopeAreasStatus.COMPLETE,
    bgColor: '#A5DDAB',
    textColor: '#167320',
  },
};

const ScopeAreaStatus = ({ status }: ScopeAreaStatusProps): JSX.Element => {
  const { text, bgColor, textColor } = statusData[status];

  return (
    <div style={{ display: 'inline-block' }}>
      <ScopeAreaStatusContainer style={{ backgroundColor: bgColor, color: textColor }}>
        <Typography sx={{ fontSize: '0.775rem', fontWeight: '700', whiteSpace: 'nowrap' }}>
          {text}
        </Typography>
      </ScopeAreaStatusContainer>
    </div>
  );
};

export default ScopeAreaStatus;
