import { DescriptionOutlined, EditNoteOutlined } from '@mui/icons-material';
import { styled, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { SourceDocument } from '../../../Types/dealGPT';

const SourceItem = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginTop: '4px',
  color: '#1979CD',
});

const SourceLink = styled('a')({
  color: '#1979CD',
  textDecoration: 'none',

  '&:visited': {
    color: '#800080',
  },
});

interface ReferenceDocumentsProps {
  documents?: SourceDocument[] | null;
}

const ReferenceDocuments: React.FC<ReferenceDocumentsProps> = ({ documents }) => {
  const [showingAll, setShowingAll] = useState(false);
  const [docsToShow, setDocsToShow] = useState(
    documents ? (documents?.length > 3 ? documents.slice(0, 2) : documents) : []
  );

  useEffect(() => {
    if (!documents) {
      setDocsToShow([]);
    } else {
      if (showingAll) {
        setDocsToShow(documents);
      } else {
        setDocsToShow(documents?.length > 2 ? documents.slice(0, 2) : documents);
      }
    }
  }, [documents, showingAll]);

  return (
    <div>
      <Typography sx={{ fontSize: '14px', fontWeight: '700', color: '#757575' }}>
        REFERENCE DOCUMENT(S)
      </Typography>
      <SourceItem sx={{ color: '#757575' }}>
        <EditNoteOutlined sx={{ fontSize: '16px' }} />
        <Typography sx={{ fontWeight: '500', marginLeft: '8px' }}>Site Visit Notes</Typography>
      </SourceItem>
      {docsToShow.map((document) => (
        <div key={document.fileName}>
          {document.fileName === 'Notes' ? (
            <SourceItem>
              <EditNoteOutlined sx={{ fontSize: '16px' }} />
              <Typography sx={{ fontWeight: '500', marginLeft: '8px' }}>
                {document.fileName}
              </Typography>
            </SourceItem>
          ) : (
            <SourceItem>
              <DescriptionOutlined sx={{ fontSize: '16px' }} />
              <SourceLink
                key={document.gleanId ?? 'notes'}
                href={document.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Typography sx={{ fontWeight: '500', marginLeft: '8px' }}>
                  {document.fileName}
                </Typography>
              </SourceLink>
            </SourceItem>
          )}
        </div>
      ))}
      {documents && documents.length > 2 && (
        <Typography>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setShowingAll(!showingAll);
            }}
            style={{
              color: '#757575',
              textDecoration: 'underline',
              verticalAlign: 'baseline',
              fontSize: '0.90rem',
              fontStyle: 'italic',
            }}
          >
            {showingAll ? 'Show Fewer' : 'Show All'}
          </a>
        </Typography>
      )}
    </div>
  );
};

export default ReferenceDocuments;
