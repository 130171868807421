import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  styled,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import type { CategoryType, ProjectRun, ProjectType } from '../../../Types/dealGPT';
import { DealGPTView } from '../../../Types/enums';
import { type Flags } from '../../Providers/FeatureProvider';
import CategoryAccordion from '../CategoryAccordion';
import ArrayFilteredDialog from '../PhaseTwo/ArrayFilteredDialog';
import StatusProgressBar from '../StatusProgressBar';

const ButtonContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: '16px',
});

const ProjectRunSelectorContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '16px',
  marginTop: '8px',
  marginBottom: '24px',

  [theme.breakpoints.up('sm')]: {
    padding: '0px',
  },
}));

type ProjectRunProps = {
  selected?: boolean;
};

const ProjectRunSelector = styled(Typography)<ProjectRunProps>(({ selected = false }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '8px',
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '125%',
  color: '#092B49',
  backgroundColor: selected ? '#EFF5F6' : '#FAF8F2',
  borderRadius: '100px',
  padding: '8px 16px',
  cursor: 'pointer',
  whiteSpace: 'nowrap',
}));

const StyledLoadingButton = styled(LoadingButton)({
  padding: '8px 22px',
  width: '224px',
  height: '36px',
  boxShadow:
    '0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)',
});

const ScopeFiltersContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '16px',
  marginTop: '8px',
  marginBottom: '24px',

  [theme.breakpoints.up('sm')]: {
    padding: '0px',
  },
}));

type ScopeFilterProps = {
  selected?: boolean;
};

const ScopeFilter = styled(Typography)<ScopeFilterProps>(({ selected = false }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '125%',
  color: '#092B49',
  backgroundColor: selected ? '#EFF5F6' : '#FAF8F2',
  borderRadius: '100px',
  padding: '8px 16px',
  cursor: 'pointer',
  whiteSpace: 'nowrap',
}));

const CategoriesContainer = styled(List)({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '100px',
});

export type DiscoveryViewProps = {
  features: Flags;
  project: ProjectType;
  scopes: string[];
  distinctScopes: string[];
  numScopeAreasSelected: number;
  selectedView: DealGPTView;
  isLoadingScopesDialog: boolean;
  isLoadingRescan: boolean;
  isLoadingReport: boolean;
  openFilterDialog: boolean;
  isSelectAllFilter: boolean;
  openRescanDialog: boolean;
  lastScanned: string;
  timer: string | null;
  expandAllCategories: boolean;
  projectRuns: ProjectRun[] | undefined;
  selectedRun: ProjectRun | undefined;
  runProgressData: CategoryType[];
  aggregateProgressData: CategoryType;
  projectRunStatus: string;
  isScopePreferenceSelected: (scope: string) => boolean;
  handleOpenFilterDialog: () => void;
  handleCloseFilterDialog: () => void;
  handleConfirmFilterDialog: () => void;
  handleOpenRescanDialog: () => void;
  handleCloseRescanDialog: () => void;
  handleGenerateReport: () => void;
  setSelectedRun: (run: ProjectRun) => void;
  isScopeSelected: (scope: string) => boolean;
  toggleScopeFilters: (scope: string) => void;
  toggleFilter: (scope: string) => void;
  handleClearFilters: () => void;
  toggleSelectAllScopeFilter: () => void;
  toggleCollapseExpandAllCategories: () => void;
};

const DiscoveryView = ({
  features,
  project,
  scopes,
  distinctScopes,
  numScopeAreasSelected,
  isLoadingRescan,
  isLoadingReport,
  isLoadingScopesDialog,
  openRescanDialog,
  openFilterDialog,
  isSelectAllFilter,
  lastScanned,
  timer,
  expandAllCategories,
  projectRuns,
  selectedRun,
  runProgressData,
  aggregateProgressData,
  projectRunStatus,
  isScopePreferenceSelected,
  handleOpenFilterDialog,
  handleCloseFilterDialog,
  handleConfirmFilterDialog,
  handleOpenRescanDialog,
  handleCloseRescanDialog,
  handleGenerateReport,
  setSelectedRun,
  isScopeSelected,
  toggleScopeFilters,
  handleClearFilters,
  toggleCollapseExpandAllCategories,
  toggleFilter,
  toggleSelectAllScopeFilter,
}: DiscoveryViewProps): JSX.Element => {
  const [isLoadingRunData, setIsLoadingRunData] = useState<boolean>(true);
  const [hasRun, setHasRun] = useState<boolean>(true);

  useEffect(() => {
    const handleSetDefaultRun = () => {
      if (projectRuns) {
        if (!selectedRun && projectRuns.length != 0) {
          // Set the first run that has ended, or the first run if none have ended
          const runToSet =
            projectRuns.filter((run) => {
              return run.endedAt;
            })[0] ?? projectRuns[0];
          setSelectedRun(runToSet);
        }
      }
    };

    handleSetDefaultRun();
  });

  useEffect(() => {
    if (projectRuns) {
      if (projectRuns.length === 0) {
        setHasRun(false);
      } else {
        setHasRun(true);
      }
    }
  }, [project.lastDiscoveryRunId, projectRuns]);

  useEffect(() => {
    setIsLoadingRunData(true);
  }, [selectedRun]);

  useEffect(() => {
    if (projectRunStatus === 'Completed Successfully') {
      setIsLoadingRunData(true);
    }
  }, [projectRunStatus]);

  useEffect(() => {
    if (runProgressData.length > 0) {
      setIsLoadingRunData(false);
    }
  }, [runProgressData]);

  const formatDateTime = (isoString: string): string => {
    const date = new Date(isoString);

    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    };

    return date.toLocaleString('en-US', options);
  };

  return (
    <div>
      <div>
        <ButtonContainer>
          {features.dealgpt_admin && (
            <StyledLoadingButton
              variant="contained"
              sx={{ bgcolor: '#092b49' }}
              loading={isLoadingRescan}
              loadingPosition="end"
              endIcon={<></>}
              onClick={timer ? handleOpenRescanDialog : handleOpenFilterDialog}
            >
              Rescan Documents
            </StyledLoadingButton>
          )}
          <StyledLoadingButton
            variant="outlined"
            loading={isLoadingReport}
            loadingPosition="end"
            endIcon={<></>}
            onClick={() => handleGenerateReport()}
          >
            Generate Report
          </StyledLoadingButton>
        </ButtonContainer>
        <ArrayFilteredDialog
          isLoading={isLoadingScopesDialog}
          filters={distinctScopes}
          selectedLength={numScopeAreasSelected}
          title="Scope Preferences"
          buttonConfirmText="Run"
          handleCloseDialog={handleCloseFilterDialog}
          handleConfirmDialog={handleConfirmFilterDialog}
          isFilterSelected={isScopePreferenceSelected}
          openDialog={openFilterDialog}
          toggleFilter={toggleFilter}
          toggleSelectAllFilter={toggleSelectAllScopeFilter}
          isSelectAllFilter={isSelectAllFilter}
        />
        <Dialog open={openRescanDialog} onClose={handleCloseRescanDialog}>
          <DialogTitle>Are you sure you want to rescan?</DialogTitle>
          <DialogContent>
            <DialogContentText>A scan is currently in progress.</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseRescanDialog}>Cancel</Button>
            <Button
              variant="contained"
              onClick={() => {
                handleCloseRescanDialog();
                handleOpenFilterDialog();
              }}
            >
              Confirm rescan
            </Button>
          </DialogActions>
        </Dialog>
        <Typography
          sx={{ fontSize: '14px', fontWeight: '300', fontStyle: 'italic', marginTop: '8px' }}
        >
          Last Scanned: {lastScanned}
        </Typography>
        {hasRun && (
          <div>
            <Typography
              sx={{ fontSize: '14px', fontWeight: '300', fontStyle: 'italic', marginTop: '8px' }}
            >
              Status: {project.projectRunStatus}
            </Typography>
            <div id="cover-while-loading" style={{ position: 'relative', minHeight: '100%' }}>
              {isLoadingRunData && (
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(255,253,247,0.8)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    zIndex: 9999,
                  }}
                >
                  <div
                    style={{
                      marginTop: '20%',
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                    }}
                  >
                    <CircularProgress />
                  </div>
                </div>
              )}
              <div style={{ width: '20%', marginTop: '24px', marginBottom: '24px' }}>
                <Typography sx={{ fontSize: '14px', fontWeight: '300', fontStyle: 'italic' }}>
                  Results:
                </Typography>
                <div style={{ height: '16px' }}>
                  <StatusProgressBar
                    quantity={aggregateProgressData.quantity}
                    quantityAnswered={aggregateProgressData.quantityAnswered}
                    quantityMoreInfoNeeded={aggregateProgressData.quantityMoreInfoNeeded}
                    quantityNotFound={aggregateProgressData.quantityNotFound}
                    quantitySkipped={aggregateProgressData.quantitySkipped}
                  />
                </div>
              </div>
              <div id="project-runs">
                <Typography sx={{ fontSize: '14px', fontWeight: '400', color: '#757575' }}>
                  Project Runs:
                </Typography>
                <ProjectRunSelectorContainer>
                  {projectRuns
                    ? projectRuns.map((run, index) => (
                        <ProjectRunSelector
                          key={run.createdAt}
                          selected={run.id === selectedRun?.id ? true : false}
                          onClick={() => setSelectedRun(run)}
                        >
                          <div style={{ display: 'block' }}>
                            Run {projectRuns.length - index}:{' '}
                            {run.endedAt ? 'Complete' : 'Incomplete'}
                          </div>
                          <div style={{ display: 'block' }}>
                            Date: {formatDateTime(run.createdAt)}{' '}
                          </div>
                        </ProjectRunSelector>
                      ))
                    : null}
                </ProjectRunSelectorContainer>
              </div>
              <div id="scopes-and-categories">
                <Typography sx={{ fontSize: '14px', fontWeight: '400', color: '#757575' }}>
                  Scope area:
                </Typography>
                <ScopeFiltersContainer maxWidth="lg" sx={{ marginBottom: '0px ' }}>
                  {scopes.map((scope) => (
                    <ScopeFilter
                      key={scope}
                      selected={isScopeSelected(scope)}
                      onClick={() => toggleScopeFilters(scope)}
                    >
                      {scope}
                      {isScopeSelected(scope) && <CloseOutlinedIcon sx={{ fontSize: '16px' }} />}
                    </ScopeFilter>
                  ))}
                  <Typography
                    sx={{
                      fontSize: '14px',
                      alignSelf: 'center',
                      color: '#1979CD',
                      cursor: 'pointer',
                    }}
                    onClick={handleClearFilters}
                  >
                    Clear Filters
                  </Typography>
                </ScopeFiltersContainer>
              </div>
              <CategoriesContainer className="categories-root">
                <Typography
                  sx={{
                    fontSize: '14px',
                    color: '#1979CD',
                    cursor: 'pointer',
                    marginBottom: '16px',
                    alignSelf: 'flex-end',
                  }}
                  onClick={toggleCollapseExpandAllCategories}
                >
                  {expandAllCategories ? 'Collapse all categories' : 'Expand all categories'}
                </Typography>
                {runProgressData.map((category) => (
                  <CategoryAccordion
                    projectId={project.id}
                    category={category}
                    runId={selectedRun?.id ?? ''}
                    latestRunId={projectRuns ? projectRuns[0].id : ''}
                    projectRunStatus={projectRunStatus}
                    key={`${category.scope}-${category.name}`}
                    expandAll={expandAllCategories}
                  />
                ))}
              </CategoriesContainer>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DiscoveryView;
