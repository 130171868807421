import { Chip, styled, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import type { ProjectType } from '../../Types/dealGPT';
import StatusProgressBar from './StatusProgressBar';

const ProjectCardContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '220px',
  backgroundColor: '#ffffff',
  borderRadius: '8px',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.10)',
  padding: '16px 24px',
  cursor: 'pointer',
  minHeight: '250px',
});

const StyledChip = styled(Chip)({
  width: 'fit-content',
  borderRadius: '4px',
  color: 'black',
  fontSize: '14px',
  fontWeight: '700',
});

export type ProjectCardProps = {
  project: ProjectType;
};

const ProjectCard = ({ project }: ProjectCardProps): JSX.Element => {
  const navigate = useNavigate();

  if (!project) {
    return <></>;
  }

  const handleProjectClick = async (id: string) => {
    navigate(`/intellio-advantage/${id}`);
  };

  return (
    <ProjectCardContainer
      id={`projectid${project.id}`}
      onClick={() => handleProjectClick(project.id)}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {project.lastAnalysisRunTime && (
          <StyledChip label={'ANALYSIS'} sx={{ backgroundColor: '#CFEAD2' }} />
        )}
        {project.lastDiscoveryRunTime && !project.lastAnalysisRunTime && (
          <StyledChip label={'DISCOVERY'} sx={{ backgroundColor: '#D3EAFF' }} />
        )}
        {!project.lastDiscoveryRunTime && !project.lastAnalysisRunTime && (
          <StyledChip label={'NOT STARTED'} sx={{ backgroundColor: 'LIGHTGREY' }} />
        )}
        <Typography sx={{ fontSize: '16px', fontWeight: '700', marginTop: '8px' }}>
          {project.name}
        </Typography>
        {project.clientName ? (
          <Typography sx={{ fontSize: '12px', fontWeight: '400' }}>
            <span style={{ fontStyle: 'italic' }}>Client:</span> {project.clientName}
          </Typography>
        ) : (
          <br />
        )}
      </div>
      {project.lastAnalysisRunTime && (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <div>
            <Typography sx={{ fontSize: '16px', fontWeight: '400', fontStyle: 'italic' }}>
              Last Analysis:
            </Typography>
            <Typography sx={{ fontSize: '16px', fontWeight: '400', fontStyle: 'italic' }}>
              {new Date(project.lastAnalysisRunTime).toLocaleString()}
            </Typography>
          </div>
        </div>
      )}
      {project.lastDiscoveryRunTime && (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <div>
            <Typography sx={{ fontSize: '16px', fontWeight: '400', fontStyle: 'italic' }}>
              Last Discovery:
            </Typography>
            <Typography sx={{ fontSize: '16px', fontWeight: '400', fontStyle: 'italic' }}>
              {new Date(project.lastDiscoveryRunTime).toLocaleString()}
            </Typography>
          </div>
          <div style={{ height: '16px', paddingBottom: '20px' }}>
            <StatusProgressBar
              quantity={project.quantityOfQuestions}
              quantityAnswered={project.quantityOfAnsweredQuestions}
              quantityMoreInfoNeeded={project.quantityOfMoreInfoNeededQuestions}
              quantityNotFound={project.quantityOfNotFoundQuestions}
              quantitySkipped={project.quantityOfSkippedQuestions}
            />
          </div>
        </div>
      )}
    </ProjectCardContainer>
  );
};

export default ProjectCard;
