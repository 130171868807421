import { styled, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import { selectAppStatus } from '../../redux/reducers/appStatusReducer';

const OutputWarningContainer = styled('div')({
  alignItems: 'center',
  borderLeft: '4px solid #B66D03',
  paddingLeft: '0.5em',
  marginTop: '5px',
});

const OutputWarning = () => {
  const { appName } = useSelector(selectAppStatus);
  return (
    <OutputWarningContainer>
      <Typography
        sx={{
          fontSize: '14px',
          fontWeight: '400',
          color: '#757575',
          lineHeight: '1.2em',
        }}
      >
        {`Always double check the output of ${appName} for inaccuracies before using it.`}
      </Typography>
    </OutputWarningContainer>
  );
};

export default OutputWarning;
