import {
  Checkbox,
  IconButton,
  ListItemText,
  MenuItem,
  Select,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';

import ToolSettingsIcon from '../../assets/tool-settings.svg';
import { ToolName, ToolNameMap } from '../../Types/enums';
import { useFeatures } from '../Providers/FeatureProvider';

const StyledSelect = styled(Select)({
  boxShadow: 'none',
  width: '30px',

  '.MuiOutlinedInput-notchedOutline': {
    border: 0,
  },
});

interface ToolIconProps {
  usingTools: boolean;
}

const StyledToolSettings = styled('img')<ToolIconProps>(({ usingTools }) => ({
  filter: usingTools
    ? 'invert(57%) sepia(91%) saturate(6518%) hue-rotate(193deg) brightness(94%) contrast(80%)'
    : 'invert(52%) sepia(3%) saturate(121%) hue-rotate(350deg) brightness(86%) contrast(89%)',
  width: '24px',
}));

export type ToolSettingsProps = {
  tools: ToolName[];
  disabledOnTools: ToolName[];
  handleToolsUpdate: (toolNames: ToolName[]) => void;
};

const ToolSettings = ({
  tools,
  disabledOnTools,
  handleToolsUpdate,
}: ToolSettingsProps): JSX.Element => {
  const [openTool, setOpenTool] = useState(false);
  const features = useFeatures();

  if (!features.show_tools) {
    return <></>;
  }

  const uniqueToolList = Array.from(new Set(tools.concat(disabledOnTools)));

  return (
    <div>
      <StyledSelect
        multiple
        value={uniqueToolList || []}
        open={openTool}
        onOpen={() => {
          setOpenTool(true);
        }}
        onClose={() => {
          setOpenTool(false);
        }}
        onChange={(event) => {
          handleToolsUpdate(event.target.value as ToolName[]);
        }}
        renderValue={() => null}
        IconComponent={() => null}
        sx={{ height: '0px' }}
      >
        {Object.values(ToolName).map((toolName, index) => {
          return (
            <MenuItem key={index} value={toolName}>
              <Checkbox
                checked={disabledOnTools.includes(toolName) || tools.includes(toolName)}
                disabled={disabledOnTools.includes(toolName)}
              />
              <ListItemText primary={ToolNameMap[toolName]} />
            </MenuItem>
          );
        })}
      </StyledSelect>
      <Tooltip title={<Typography variant="caption">Enable Tools</Typography>}>
        <IconButton onClick={() => setOpenTool(true)} style={{ left: '25px' }}>
          <StyledToolSettings
            src={ToolSettingsIcon}
            usingTools={uniqueToolList.length > 0}
            alt={'Enable Tools'}
          />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default ToolSettings;
