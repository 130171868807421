import { styled, Typography } from '@mui/material';
import React from 'react';

const tileColors = {
  '1': '#E02F10',
  '2': '#ED7D31',
  '3': '#FDB415',
  '4': '#70AD47',
};

const RatingTileContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '3px',
  marginRight: '12px',
});

type RatingTileProps = {
  filled: boolean;
  color: string;
};

const RatingTile = styled('div')<RatingTileProps>(({ filled, color }) => ({
  width: '28px',
  height: '8px',
  borderRadius: '100px',
  backgroundColor: filled ? color : '#E8ECEF',
}));

interface RubricOverallRatingProps {
  rating: number;
}

const RubricOverallRating: React.FC<RubricOverallRatingProps> = ({ rating }) => {
  const roundedRating = Math.round(rating);

  const getTileColor = (index: number) => {
    if (roundedRating >= 4) return tileColors['4'];
    if (roundedRating >= 3 && index < 3) return tileColors['3'];
    if (roundedRating >= 2 && index < 2) return tileColors['2'];
    if (roundedRating >= 1 && index < 1) return tileColors['1'];
    return '#E8ECEF';
  };

  const isFilled = (index: number) => {
    if (roundedRating === 5) return index < 5;
    if (roundedRating >= 4) return index < 4;
    if (roundedRating >= 3) return index < 3;
    if (roundedRating >= 2) return index < 2;
    if (roundedRating >= 1) return index < 1;
    return false;
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <RatingTileContainer>
        {[0, 1, 2, 3, 4].map((_, index) => (
          <RatingTile key={index} filled={isFilled(index)} color={getTileColor(index)} />
        ))}
      </RatingTileContainer>
      <Typography sx={{ fontSize: '14px', color: '#2A2C2F' }}>{rating.toFixed(1)}</Typography>
    </div>
  );
};

export default RubricOverallRating;
