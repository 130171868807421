import { Container, Grid, IconButton, styled } from '@mui/material';
import { Link as ReactLink } from 'react-router-dom';

import { useIsMobile } from '../util/useIsMobile';

export const drawerWidth = '350px';

export const PageContainer = styled('div')({
  display: 'flex',
  height: '100vh',

  div: {
    position: 'relative',
  },
});

export const StyledLink = styled(ReactLink)({
  textDecoration: 'none',
  color: 'inherit',
  '&:active, &:visited, &:hover, &:focus': {
    color: 'inherit',
  },
});

export const StyledChatContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: 'auto',
  marginRight: 'auto',
  justifyContent: 'space-between',
  minHeight: '100%',
  '& > *:not(:last-child)': {
    textAlign: 'center',
  },
});

export const ChatContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
});

export const ChatRow = styled('div')({
  display: 'flex',
  gap: '5px',
  marginBottom: '16px',
  alignItems: 'flex-end',
  position: 'sticky',
  bottom: '0',
  background: '#fffdf8',
  paddingTop: '10px',
});

export const SendButton = styled(IconButton)(() => {
  const isMobile = useIsMobile();
  return {
    paddingBottom: isMobile ? '68px' : '0',
    height: '40px',
    width: '40px',
  };
});

export const ProfileAndNotesDiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginTop: '20px',
  paddingRight: '200px',
  paddingLeft: '200px',
});

export const StyledGridContainer = styled(Grid)({
  display: 'flex',
  flexGrow: 1,
});

export const DrawerContainer = styled('div')(({ open }: { open: boolean }) => ({
  width: open ? drawerWidth : 'auto',
  zIndex: '1',
}));

export const MainContainer = styled('div')<{ open: boolean }>(({ open }) => ({
  marginLeft: open ? 'auto' : `-${drawerWidth}`,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  zIndex: 1,
  alignItems: 'flex-start',
  height: 'inherit',
}));
