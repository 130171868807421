import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

import { AppEnum, AppNameBannerMap, Llm } from '../../Types/enums';

interface AppStatusState {
  selectedApp: AppEnum;
  appName: string;
  llm: Llm;
}

const initialState: AppStatusState = {
  selectedApp: AppEnum.NIGEL,
  appName: AppNameBannerMap[AppEnum.NIGEL],
  llm: Llm.GPT_4O_128K,
};

const appStateSlice = createSlice({
  name: 'appStatus',
  initialState,
  reducers: {
    setCurrentApp: (state, action: PayloadAction<AppEnum>) => {
      state.selectedApp = action.payload;
      state.appName = AppNameBannerMap[action.payload];
    },
    setLLM: (state, action: PayloadAction<Llm>) => {
      state.llm = action.payload;
    },
  },
});

export const { setCurrentApp, setLLM } = appStateSlice.actions;

export const selectAppStatus = (state: RootState) => state.appStatus;

export default appStateSlice.reducer;
