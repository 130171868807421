import React, { ReactNode } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import store from '../../redux/store';
import { AnalyticsProvider } from './AnalyticsProvider';
import { AuthProvider } from './AuthProvider';
import { ErrorProvider } from './ErrorProvider';
import { FeatureProvider } from './FeatureProvider';
import { FormbricksProvider } from './FormbricksProvider';
import { JoyrideProvider } from './JoyrideProvider';
import { NavBarRoutesProvider } from './NavbarRoutesProvider';
import { ProjectsProvider } from './ProjectsProvider';

interface Props {
  children: ReactNode;
}

const Providers: React.FC<Props> = ({ children }) => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <ErrorProvider>
          <Provider store={store}>
            <JoyrideProvider>
              <FeatureProvider>
                <AnalyticsProvider>
                  <FormbricksProvider>
                    <ProjectsProvider>
                      <NavBarRoutesProvider>{children}</NavBarRoutesProvider>
                    </ProjectsProvider>
                  </FormbricksProvider>
                </AnalyticsProvider>
              </FeatureProvider>
            </JoyrideProvider>
          </Provider>
        </ErrorProvider>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default Providers;
