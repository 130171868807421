import { configureStore } from '@reduxjs/toolkit';
import { logger } from 'redux-logger';

import rootReducer from './reducers';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return !['prod', 'staging', 'demo'].includes(import.meta.env.VITE_ENVIRONMENT)
      ? getDefaultMiddleware().concat(logger)
      : getDefaultMiddleware();
  },
  devTools: !['prod', 'staging', 'demo'].includes(import.meta.env.VITE_ENVIRONMENT),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
