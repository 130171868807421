import { EmbeddingsFile } from '../Types/embeddings';
import { API_URL, enrichedFetch } from './api';

export const getEmbeddingsFiles = async (): Promise<EmbeddingsFile[]> => {
  return (await enrichedFetch(`${API_URL}/embeddings`)) as Promise<EmbeddingsFile[]>;
};

export const postEmbeddingsFile = async (formData: FormData): Promise<void> => {
  return (await enrichedFetch(`${API_URL}/embeddings`, {
    method: 'POST',
    body: formData,
  })) as Promise<void>;
};

export const deleteEmbeddingsFile = async (fileId: string): Promise<EmbeddingsFile> => {
  return (await enrichedFetch(`${API_URL}/embeddings/${fileId}`, {
    method: 'DELETE',
  })) as EmbeddingsFile;
};
