import { useMsal } from '@azure/msal-react';
import { enqueueSnackbar } from 'notistack';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { registerErrorFunction } from '../../services/api';
import { useAuth } from './AuthProvider';

export class StatusError extends Error {
  status: number;
  constructor(message: string, status: number) {
    super(message);
    this.status = status;
    Object.setPrototypeOf(this, StatusError.prototype);
  }
}

interface ErrorContextType {
  error: StatusError | null;
  setError: React.Dispatch<React.SetStateAction<StatusError | null>>;
}

const ErrorContext = createContext<ErrorContextType | undefined>(undefined);

interface ErrorProviderProps {
  children: ReactNode;
}

export function ErrorProvider({ children }: ErrorProviderProps) {
  const [error, setError] = useState<StatusError | null>(null);
  const [lastErrorMessage, setLastErrorMessage] = useState<string | null>(null);
  const [lastErrorTimestamp, setLastErrorTimestamp] = useState<number | null>(null);
  const { instance } = useMsal();
  const { logout } = useAuth();

  const repeatMessageDelayMS = 10000;

  const errorFunction = useCallback(
    async (error: StatusError) => {
      if (error.status === 401) {
        // Handle edge case where a user logged out while viewing a conversation, truncate the
        // url to only be chat.
        let pathname = window.location.pathname;

        if (pathname.startsWith('/chat')) {
          pathname = '/chat';
        }
        await logout(`${pathname}${location.search}`);
        enqueueSnackbar(
          'Your session has expired or you are not authenticated. Please log in again.',
          {
            variant: 'error',
          }
        );
      }
      return false;
    },
    [logout]
  );

  useEffect(() => {
    // Automatically register the setError function
    registerErrorFunction(errorFunction);
  }, [errorFunction]);

  useEffect(() => {
    const handleErrorInternal = () => {
      if (error && error.status != 401) {
        // Authorization handles 401 error messaging
        const currentTime = Date.now();

        if (
          error.message !== lastErrorMessage ||
          (lastErrorTimestamp && currentTime - lastErrorTimestamp >= repeatMessageDelayMS)
        ) {
          enqueueSnackbar(error.message, { variant: 'error' });
          setLastErrorMessage(error.message);
          setLastErrorTimestamp(currentTime);
        }
      }
    };
    handleErrorInternal();
  }, [error, instance, lastErrorMessage, lastErrorTimestamp]);

  return <ErrorContext.Provider value={{ error, setError }}>{children}</ErrorContext.Provider>;
}

export const useError = () => {
  const context = useContext(ErrorContext);
  if (!context) {
    throw new Error('useError must be used within an ErrorProvider');
  }
  return context;
};
