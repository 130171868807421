import { CircularProgress, styled, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import { selectConversations } from '../../redux/reducers/conversationReducer';
import { formatTokens } from '../../util/formatterUtils';
import OutputWarning from './OutputWarning';

const WarningContainer = styled('div')({
  paddingTop: '5px',
  display: 'flex',
  gap: '10px',
  '@media (max-width: 600px)': {
    flexDirection: 'column',
  },
});

const ErrorAndOutputWarningContainer = styled('div')({
  width: '80%',
  '@media (max-width: 600px)': {
    width: '100%',
    order: 2,
    marginLeft: '5px',
  },
});

const ErrorContainer = styled('div')({});

const ErrorMessage = styled(Typography)({
  fontSize: '12px',
  paddingBottom: '3px',
});

const TokenTrackerContainer = styled('div')({
  display: 'flex',
  gap: '5px',
  '@media (max-width: 600px)': {
    order: 1,
    marginTop: '5px',
    marginBottom: '-5px',
    marginLeft: '-20px',
  },
});

const colorValue = (value = 0) => {
  switch (true) {
    case value <= 50:
      return `#00A3FF`;

    case value > 50 && value <= 75:
      return `#AF01FF`;

    case value > 75:
      return `#FF3D00`;
  }
};

const StyledCircularProgress = styled(CircularProgress)(({ value }) => ({
  color: `${colorValue(value)}`,
  strokeWidth: '4',
  transition: 'color 1s ease-in-out',
}));

const LimitWarning = () => {
  const { currentNumTokens, maxNumTokens } = useSelector(selectConversations);

  const percentage = currentNumTokens / maxNumTokens;
  const progressValue = percentage >= 1 ? 1 : percentage;

  return (
    <WarningContainer>
      <ErrorAndOutputWarningContainer>
        <ErrorContainer>
          {percentage > 0.75 && percentage < 1 && (
            <ErrorMessage>
              AI models can only handle so much data, and this conversation is nearing that limit.
              Proceed with caution! The software might retaliate with a sassy error message or an
              existential crisis if you test its limits too far.
            </ErrorMessage>
          )}
          {percentage >= 1 && (
            <ErrorMessage sx={{ color: 'red' }}>
              Oops! The length of this conversation is at the AI models limit. It&apos;s having an
              having an existential crisis, questioning the very fabric of its programming. You can
              start a new prompt with a new topic or question.
            </ErrorMessage>
          )}
        </ErrorContainer>
        <OutputWarning />
      </ErrorAndOutputWarningContainer>
      <TokenTrackerContainer>
        <StyledCircularProgress
          variant="determinate"
          aria-label={'Token Limit'}
          value={progressValue * 100}
          style={{ height: 20, width: 20 }}
        />
        <Typography variant="body2" sx={{ marginTop: '6px' }}>
          {formatTokens(currentNumTokens)} of {formatTokens(maxNumTokens)} tokens
        </Typography>
      </TokenTrackerContainer>
    </WarningContainer>
  );
};

export default LimitWarning;
