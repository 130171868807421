import styled from '@emotion/styled';
import { OpenInNew } from '@mui/icons-material';
import {
  Alert,
  Button,
  Container,
  Grid,
  MenuItem,
  Pagination,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import DebouncedSearchInput from '../components/DebouncedSearchInput';
import NavDrawer from '../components/Drawer/NavDrawer';
import NavBar, { NavBarMode } from '../components/NavBar';
import PromptCard from '../components/Prompts/PromptCard';
import PromptFilters from '../components/Prompts/PromptFilters';
import { useAuth } from '../components/Providers/AuthProvider';
import { useFeatures } from '../components/Providers/FeatureProvider';
import { findPrompts } from '../redux/actions/promptActions';
import {
  selectPrompts,
  setPage,
  setPublishedOnly,
  setSearchQuery,
  setSortDirection,
  setSortOption,
} from '../redux/reducers/promptReducer';
import { AppDispatch } from '../redux/store';
import { drawerWidth, MainContainer, PageContainer } from '../theme/CustomComponents';
import { PromptSortOptions, SortDirection } from '../Types/enums';
import { Prompt } from '../Types/prompt';
import { useIsMobile } from '../util/useIsMobile';

const StyledAlert = styled(Alert)({
  marginBottom: '20px',
  marginTop: '15px',
  backgroundColor: '#FFE9CA',
  color: 'black',
  '.MuiAlert-icon': { display: 'flex', alignItems: 'center' },
});

const CardContainer = styled('div')({
  display: 'flex',
  gap: '10px',
  flexWrap: 'wrap',
  justifyContent: 'center',
  paddingBottom: '20px',
});

const StyledLink = styled('a')({
  color: 'black',
  textDecoration: 'none',
  ':hover': {
    textDecoration: 'underline',
  },
});

const SearchContainer = styled('div')(() => {
  const isMobile = useIsMobile();
  return {
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    justifyContent: 'space-between',
    gap: '30px',
    paddingBottom: '20px',
  };
});

const SortDropdownContainer = styled('div')(() => {
  return {
    display: 'flex',
    gap: '15px',
    width: '100%',
  };
});

const StyledPagination = styled(Pagination)({
  paddingBottom: '20px',
  'button.Mui-selected': {
    backgroundColor: 'rgba(25, 118, 210, 0.1)',
  },
});

const Prompts = ({ myPrompts }: { myPrompts: boolean }): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    prompts,
    isLoading,
    searchQuery,
    page,
    publishedOnly,
    sortOption,
    sortDirection,
    selectedTags,
    totalPages,
  } = useSelector(selectPrompts);
  const { currentUser } = useAuth();
  const [filterDrawerOpen, setFilterDrawerOpen] = useState<boolean>(
    window.matchMedia('(min-width: 960px)').matches
  );
  const navigate = useNavigate();
  const features = useFeatures();

  const sortOptions = [
    { label: 'Default', value: PromptSortOptions.DEFAULT },
    { label: 'Likes', value: PromptSortOptions.LIKES },
    { label: 'Usage', value: PromptSortOptions.USAGE },
  ];

  const sortDirectionOptions = [
    { label: 'High to Low', value: SortDirection.DESC },
    { label: 'Low to High', value: SortDirection.ASC },
  ];

  useEffect(() => {
    function handleResize() {
      const width = window.innerWidth;

      if (width < 1200) {
        setFilterDrawerOpen(false);
      } else {
        setFilterDrawerOpen(true);
      }
    }
    window.addEventListener('resize', handleResize);
  });

  useEffect(() => {
    dispatch(setPublishedOnly(!myPrompts));
  }, [myPrompts, searchQuery, dispatch]);

  useEffect(() => {
    dispatch(findPrompts());
  }, [
    currentUser?.id,
    publishedOnly,
    page,
    sortOption,
    searchQuery,
    sortDirection,
    selectedTags,
    dispatch,
  ]);

  const handleSortChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSortOption(e.target.value as PromptSortOptions));

    if (e.target.value === PromptSortOptions.DEFAULT) {
      dispatch(setSortDirection(SortDirection.DESC));
    }
    if (!sortDirection && e.target.value !== PromptSortOptions.DEFAULT) {
      dispatch(setSortDirection(SortDirection.DESC));
    }
  };

  const handleSortDirectionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSortDirection(e.target.value as SortDirection));
  };

  const handlePageChange = (_e: React.ChangeEvent<unknown>, value: number) => {
    dispatch(setPage(value));
  };

  const paginator = (
    <StyledPagination
      count={totalPages}
      page={page}
      onChange={handlePageChange}
      size={'small'}
      sx={{ justifyContent: 'center', display: 'flex' }}
    />
  );

  return (
    <PageContainer>
      <NavDrawer
        drawerWidth={drawerWidth}
        drawerOpen={filterDrawerOpen}
        setDrawerOpen={setFilterDrawerOpen}
        showNewChatButton={false}
        showConversationList={false}
      >
        <PromptFilters />
      </NavDrawer>
      <MainContainer open={filterDrawerOpen}>
        <NavBar mode={NavBarMode.NIGEL} />
        <div style={{ overflow: 'auto', width: '100%', minHeight: 'calc(100% - 64px)' }}>
          <Container maxWidth="md">
            <StyledAlert icon={`👋`}>
              <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                <span style={{ fontWeight: '700' }}>Pro tip:</span> if you want to learn more about
                prompts, check out this{' '}
                <StyledLink
                  href={
                    'https://learn.microsoft.com/en-us/azure/ai-services/openai/concepts/prompt-engineering'
                  }
                  target="_blank"
                >
                  <OpenInNew sx={{ verticalAlign: 'top', height: '18px' }} />
                  <span style={{ verticalAlign: 'top' }}>Intro to Prompt Engineering</span>
                </StyledLink>
                . And if you really want to nerd out, see this{' '}
                <StyledLink href={'https://github.com/brexhq/prompt-engineering'} target="_blank">
                  <OpenInNew sx={{ verticalAlign: 'top', height: '18px' }} />
                  <span style={{ verticalAlign: 'top' }}>Prompt Engineering Guide</span>
                </StyledLink>
                .
              </Typography>
            </StyledAlert>
            <div>
              {!publishedOnly && (
                <Button
                  sx={{ marginBottom: '20px', display: 'inline-block' }}
                  variant="contained"
                  onClick={() => {
                    navigate('/prompt-edit');
                  }}
                >
                  Create Prompt
                </Button>
              )}
            </div>
            <SearchContainer>
              <DebouncedSearchInput
                value={searchQuery}
                valueUpdated={(value) => {
                  dispatch(setSearchQuery(value));
                }}
              />
              <SortDropdownContainer>
                <TextField
                  sx={{ width: '50%' }}
                  value={sortOption}
                  onChange={handleSortChange}
                  label={'Sort'}
                  select
                >
                  {sortOptions.map((option) => (
                    <MenuItem value={option.value} key={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  value={sortDirection || ''}
                  onChange={handleSortDirectionChange}
                  label={'Sort Direction'}
                  disabled={sortOption === PromptSortOptions.DEFAULT}
                  select
                  sx={{ width: '50%' }}
                >
                  {sortDirectionOptions.map((option) => (
                    <MenuItem value={option.value} key={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </SortDropdownContainer>
            </SearchContainer>
            {paginator}

            {isLoading ? (
              <Grid container columnSpacing={-10} rowSpacing={2} sx={{ width: '100% ' }}>
                {Array(6)
                  .fill(null)
                  .map((_, index) => (
                    <Grid key={index} item xs={12}>
                      <Skeleton
                        variant="rounded"
                        width="100%"
                        height="101px"
                        animation="wave"
                        style={{
                          borderRadius: '20px',
                          backgroundColor: '#F3F6F4',
                        }}
                      />
                    </Grid>
                  ))}
              </Grid>
            ) : (
              <CardContainer>
                {prompts.filter(
                  // If user has access to handbook index, allow all prompts to be displayed
                  // otherwise, filter out handbook prompts. Use similar solution for future index filtering where
                  // check if user has access to the app index, if not, filter out prompts for that index
                  (prompt: Prompt) =>
                    features.app_employee_handbook_access ||
                    prompt.appName !== 'WM_EMPLOYEE_HANDBOOK'
                ).length === 0 ? (
                  selectedTags.length === 0 && searchQuery === '' ? (
                    <Typography>No prompts, create one!</Typography>
                  ) : (
                    <Typography>No prompts found for given filters</Typography>
                  )
                ) : (
                  prompts
                    .filter(
                      (prompt: Prompt) =>
                        features.app_employee_handbook_access ||
                        prompt.appName !== 'WM_EMPLOYEE_HANDBOOK'
                    )
                    .map((prompt: Prompt) => {
                      if (prompt)
                        return (
                          <PromptCard
                            prompt={prompt}
                            key={prompt.id}
                            onPromptEdit={() => {
                              dispatch(findPrompts());
                            }}
                            publishedOnly={publishedOnly}
                          />
                        );
                    })
                )}
              </CardContainer>
            )}
            {paginator}
          </Container>
        </div>
      </MainContainer>
    </PageContainer>
  );
};
export default Prompts;
