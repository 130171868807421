import { PayloadAction } from '@reduxjs/toolkit';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { AppDispatch } from 'redux/store';

import {
  getConversation,
  getConversations,
  getMessages,
  postConversation,
  postMessage,
  setCurrentMessagesAsync,
} from '../../../redux/actions/conversationActions';
import { setCurrentApp } from '../../../redux/reducers/appStatusReducer';
import {
  selectConversations,
  setCurrentConversation,
  setCurrentNumTokens,
  setIsLoading,
} from '../../../redux/reducers/conversationReducer';
import { Conversation, Message, MessageFile } from '../../../Types/conversation';
import { ProjectType } from '../../../Types/dealGPT';
import { AppEnum, Llm, MessageFrom } from '../../../Types/enums';
import { Prompt } from '../../../Types/prompt';
import { useFeatures } from '../../Providers/FeatureProvider';
import DealGPTChatView from '../Views/DealGPTChatView';

export type DealGPTChatProps = {
  project: ProjectType;
};

const DealGPTChat = ({ project }: DealGPTChatProps): JSX.Element => {
  const { conversationId } = useParams();
  const messageContainerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>();
  const { enqueueSnackbar } = useSnackbar();
  const [errorMessage, setErrorMessage] = useState('');
  const [showChatInfo, setShowChatInfo] = useState<boolean>(false);

  const navigate = useNavigate();
  const features = useFeatures();
  const dispatch = useDispatch<AppDispatch>();
  const { currentConversation, currentMessages } = useSelector(selectConversations);
  // const analytics = useAnalytics();
  const currentMessagesRef = useRef(currentMessages);

  const llm = Llm.GLEAN;
  const gleanApplicationId = project.gleanAppId;
  const projectChatRoute = `/intellio-advantage/${project.id}/chat`;

  const getUserInput = () => {
    if (inputRef.current) {
      return inputRef.current.value;
    }
    return '';
  };

  const submitMessage = useCallback(
    (message: string, files: MessageFile[], conversationId: string) => {
      const newMessages = [
        ...currentMessagesRef.current,
        {
          conversationId,
          message,
          from: MessageFrom.USER,
          createdAt: '',
          id: '',
          deleted: false,
          tokens: 0,
          files,
        },
      ];
      currentMessagesRef.current = newMessages;
      dispatch(setCurrentMessagesAsync(newMessages)).then(() => {
        dispatch(
          postMessage({
            message: { conversationId, message, files },
            llm,
            gleanApplicationId,
          })
        ).then(() => {
          if (window.location.pathname === projectChatRoute) {
            dispatch(getConversations());
          }

          dispatch(getMessages(conversationId)).then(
            (action: PayloadAction<Message[] | unknown>) => {
              dispatch(setCurrentMessagesAsync(action.payload as Message[])).then(() => {
                dispatch(setIsLoading(false));
                navigate(`${projectChatRoute}/${conversationId}`);
              });
            }
          );
        });
      });
    },
    [dispatch, llm, gleanApplicationId, navigate, projectChatRoute]
  );

  const createNewConversation = useCallback(
    (message: string, tempUploadedFiles: MessageFile[], newConversationTitle: string) => {
      dispatch(
        postConversation({
          title: newConversationTitle,
          promptId: undefined,
          tools: [],
          appName: AppEnum.DEAL_GPT,
          projectId: project.id,
        })
      ).then((action: PayloadAction<Conversation | unknown>) => {
        const newConversation = action.payload as Conversation;

        submitMessage(message, tempUploadedFiles, newConversation.id);
      });
    },
    [dispatch, submitMessage, project.id]
  );

  const handleSubmit = useCallback(
    (_prompt?: Prompt, input?: string) => {
      if (input && conversationId) {
        dispatch(setIsLoading(true));
        submitMessage(input, [], conversationId);
      } else if (inputRef.current) {
        const ref = inputRef.current;

        if (ref.value === '') {
          setErrorMessage('Please enter your message!');
          setTimeout(() => {
            setErrorMessage('');
          }, 5000);
          return;
        }

        dispatch(setIsLoading(true));
        setShowChatInfo(false);
        const message = getUserInput();

        ref.value = '';

        let newConversationTitle;

        // No conversationId from params (on deal-gpt/:projectId/chat)
        if (!conversationId) {
          newConversationTitle = message;

          createNewConversation(message, [], newConversationTitle);
        }

        // ConversationId from params (on deal-gpt/:projectId/chat/:conversationId)
        else {
          submitMessage(message, [], conversationId);
        }
      }
      if (inputRef.current) {
        inputRef.current.focus();
      }
    },
    [
      conversationId,
      createNewConversation,
      submitMessage,
      dispatch,
      // analytics,
    ]
  );

  //triggers when a different conversation is selected
  useEffect(() => {
    if (currentConversation?.id !== conversationId) {
      if (conversationId) {
        dispatch(setIsLoading(true));
        dispatch(getConversation(conversationId))
          .then((action: PayloadAction<Conversation | unknown>) => {
            const newConversation = action.payload as Conversation;
            setCurrentConversation(newConversation);
            dispatch(setCurrentApp(newConversation.appName));
            if (inputRef.current) {
              inputRef.current.value = '';
            }

            dispatch(getMessages(conversationId)).then(
              (action: PayloadAction<Message[] | unknown>) => {
                const newMessages = action.payload as Message[];
                if (
                  currentConversation?.prompt &&
                  currentConversation.prompt.userEditRequired === false
                ) {
                  if (newMessages.length > 0) {
                    newMessages.shift();
                  }
                }

                dispatch(setCurrentMessagesAsync(newMessages)).then(() => {
                  const mostRecentAIMessages = newMessages.filter(
                    (message) => message.from === MessageFrom.AI
                  );
                  dispatch(
                    setCurrentNumTokens(
                      mostRecentAIMessages.length > 0
                        ? mostRecentAIMessages[mostRecentAIMessages.length - 1].tokens
                        : 0
                    )
                  );
                  dispatch(setIsLoading(false));
                });
              }
            );
          })
          .catch((e) => {
            console.log(e);
            enqueueSnackbar('Error fetching conversation', { variant: 'error' });
            setCurrentConversation(undefined);
          });
      } else {
        setCurrentConversation(undefined);
      }
    }
  }, [conversationId, currentConversation, enqueueSnackbar, dispatch]);

  // If switching to /intellio-advantage/:projectId/chat from /intellio-advantage/:projectId/chat/:conversationId
  useEffect(() => {
    if (currentConversation?.id && !conversationId) {
      dispatch(setCurrentMessagesAsync([]));
      dispatch(setCurrentNumTokens(0));
      dispatch(setCurrentConversation(undefined));
    }
  }, [conversationId, currentConversation, dispatch]);

  // scroll to the bottom of the messages when anything updates
  useEffect(() => {
    const scrollToBottom = () => {
      if (messageContainerRef.current) {
        messageContainerRef.current.scrollTo({
          top: messageContainerRef.current.scrollHeight,
          behavior: 'smooth',
        });
      }
    };

    // Ensure the scroll happens after the DOM update
    setTimeout(scrollToBottom, 100);
  }, [dispatch, currentMessages]);

  // updates the currentMessagesRef when currentMessages updates
  useEffect(() => {
    currentMessagesRef.current = currentMessages;
  }, [currentMessages]);

  // triggers when a different app is selected
  useEffect(() => {
    setShowChatInfo(window.location.pathname === `${projectChatRoute}`);
  }, [navigate, projectChatRoute]);

  // useEffect(() => {
  //   analytics.chatMode({ app: AppEnum.DEAL_GPT });
  // }, [analytics]);

  return (
    <DealGPTChatView
      features={features}
      project={project}
      llm={llm}
      showChatInfo={showChatInfo}
      errorMessage={errorMessage}
      inputRef={inputRef}
      handleSubmit={handleSubmit}
    />
  );
};

export default DealGPTChat;
