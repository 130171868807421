import React from 'react';

import DealGPTProjectContainer from '../../components/DealGPT/ViewModels/DealGPTProjectContainer';
import { DealGPTView } from '../../Types/enums';

export type DealGPTProjectProps = {
  selectedView: DealGPTView;
};

const DealGPTProject = ({ selectedView }: DealGPTProjectProps): JSX.Element => (
  <DealGPTProjectContainer selectedView={selectedView} />
);

export default DealGPTProject;
