/**
 * designTokens.ts
 *
 * This file defines and exports the design tokens used throughout the application.
 * Design tokens are a set of variables that store design-related values such as colors,
 * typography, spacing, etc. In this case, the design tokens are primarily focused on colors.
 *
 * By centralizing these values in a single file, it ensures consistency and maintainability
 * across the application. When a design value needs to be updated, it can be changed in this
 * file, and the changes will be reflected throughout the entire application.
 *
 * This is where the hardcoded colors are stored in either hex or RGBA notation.
 */

export const designTokens = {
  base: {
    blue: {
      100: '#F4F8FA',
      200: '#E6F0F6',
      300: '#B1E3FF',
      400: '#72CCFF',
      500: '#36A8FA',
      600: '#128ADF',
      700: '#0979C9',
      800: '#015FA1',
      900: '#003D68',
    },
    neutral: {
      100: '#F1F4F6',
      200: '#E8ECEF',
      300: '#D5DCE1',
      400: '#B8C1C7',
      500: '#959EA4',
      600: '#6D757A',
      700: '#484A4D',
      800: '#2A2C2F',
      900: '#212325',
      black: '#000000',
      white: '#FFFFFF',
    },
    green: {
      100: '#CDF1C0',
      200: '#A0EB84',
      300: '#68D33F',
      400: '#46B51C',
      500: '#339010',
      600: '#2A810A',
      700: '#216A05',
      800: '#174F02',
      900: '#113101',
    },
    red: {
      100: '#F1F4F6',
      200: '#E8ECEF',
      300: '#FF9A7A',
      400: '#FF764B',
      500: '#F8470C',
      600: '#DE3F08',
      700: '#484A4D',
      800: '#2A2C2F',
      900: '#212325',
    },
    violet: {
      100: '#EDDCFF',
      200: '#D7AFFF',
      300: '#BD7BFF',
      400: '#A34DFA',
      500: '#8F27F7',
      600: '#7B0CEA',
      700: '#7007D8',
      800: '#5E04B8',
      900: '#47018C',
    },
    brand: {
      wmblue: '#003D6A',
      yellowseed: '#FFDE32',
    },
  },
  utility: {
    text: {
      primary: '#2A2C2F', // Neutral 800
      secondary: '#959EA4', // Neutral 500
      buttonPrimary: '#FFFFFF', // Neutral White
      buttonSecondary: '#6D757A', // Neutral 600
      link: '#0979C9', // Blue 700
      error: '#F8470C', // Red 500
      success: '#46B51C', // Green 400
    },
    icon: {
      primary: '#2A2C2F', // Neutral 800
      secondary: '#6D757A', // Neutral 600
      buttonActive: '#FFFFFF', // Neutral White
      link: '#0979C9', // Blue 700
      error: '#F8470C', // Red 500
      success: '#46B51C', // Green 400
    },
    surface: {
      page: '#F4F8FA', // Blue 100
      overlay: '#FFFFFF', // Neutral White
      card: '#FFFFFF', // Neutral White
      container: '#FFFFFF', // Neutral White
      tagPrimary: '#E8ECEF', // Neutral 200
      buttonPrimary: '#0979C9', // Blue 700
      buttonPrimaryHover: '#36A8FA', // Blue 500
      hover: '#F4F8FA', // Blue 100
    },
    border: {
      divider: '#E8ECEF', // Neutral 200
      input: '#B8C1C7', // Neutral 400
      card: '#E8ECEF', // Neutral 200
      focus: '#36A8FA', // Blue 500
    },
    fillOpacity: {
      black: {
        100: 'rgba(0, 0, 0, 0.04)',
        200: 'rgba(0, 0, 0, 0.08)',
        300: 'rgba(0, 0, 0, 0.16)',
        400: 'rgba(0, 0, 0, 0.40)',
        500: 'rgba(0, 0, 0, 0.50)',
        600: 'rgba(0, 0, 0, 0.65)',
      },
      white: {
        100: 'rgba(255, 255, 255, 0.04)',
        200: 'rgba(255, 255, 255, 0.08)',
        300: 'rgba(255, 255, 255, 0.16)',
        400: 'rgba(255, 255, 255, 0.40)',
        500: 'rgba(255, 255, 255, 0.50)',
        600: 'rgba(255, 255, 255, 0.65)',
      },
    },
  },
};
