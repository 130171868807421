import React, { createContext, useContext, useEffect, useState } from 'react';
import { AppEnum } from 'Types/enums';
import { v4 as uuidv4 } from 'uuid';

import type { ArgumentType } from '../../Types/gtag.d';
import { useAuth } from './AuthProvider';

declare global {
  interface Window {
    gtag: (...args: ArgumentType[]) => void;
  }
}

type SessionOptions = {
  userId: string;
  sessionId: string;
};

type SessionStorage = {
  value: SessionOptions;
  expires: number;
};

export type MessageOptions = {
  conversationId: string;
  messageId: string;
  overridedTimestamp?: string;
};

export type ChatModeOptions = {
  app: AppEnum;
};

export type FileOptions = {
  fileId: string;
  fileSize: number;
  overridedTimestamp?: string;
};

export type ScanOptions = {
  projectId: string;
};

type OverridedOptions = {
  timestamp: string;
  newOptions: Record<string, unknown>;
};

interface AnalyticsContextType {
  getTimestamp: () => string;
  identify: () => void;
  message: (status: 'started' | 'ended', options: MessageOptions) => void;
  fileUpload: (status: 'started' | 'ended', options: FileOptions) => void;
  chatMode: (options: ChatModeOptions) => void;
  scan: (options: ScanOptions) => void;
  track: (category: string, label: string, options?: Record<string, unknown>) => void;
}

interface Props {
  children: React.ReactNode;
}

export const AnalyticsHelper = (): AnalyticsContextType => {
  function getStorage(): SessionStorage | null {
    const value: string | null = localStorage.getItem('_session');
    const result: SessionStorage | null = value ? (JSON.parse(value) as SessionStorage) : null;

    return result;
  }

  function setStorage(userId: string, sessionId: string): SessionStorage {
    const now: number = Date.now();

    const storage: SessionStorage = {
      value: {
        userId: userId,
        sessionId: sessionId,
      },
      expires: now + 1000 * 60 * 60,
    };

    localStorage.setItem('_session', JSON.stringify(sessionStorage));
    return storage;
  }

  const [session, setSession] = useState<SessionOptions | null>(null);
  const { initialized, currentUser } = useAuth();

  useEffect(() => {
    if (!initialized) {
      return;
    }

    const userId: string = currentUser?.id || '';

    let storage = getStorage();
    let sessionId = '';
    if (!storage || storage.expires < Date.now()) {
      sessionId = uuidv4();
    }

    storage = setStorage(userId, sessionId);
    setSession(storage.value);
  }, [initialized, currentUser?.id]);

  function getTimestamp(): string {
    const now = new Date();
    return new Date(now.getTime() - now.getTimezoneOffset() * 60000).toISOString();
  }

  function getOverridedOptions(options: Record<string, unknown>): OverridedOptions {
    const timestamp: string = options.overridedTimestamp
      ? (options.overridedTimestamp as string)
      : getTimestamp();

    const newOptions = options;
    delete newOptions.overridedTimestamp;

    return {
      timestamp,
      newOptions,
    };
  }

  const contextType: AnalyticsContextType = {
    getTimestamp: (): string => {
      return getTimestamp();
    },
    identify: (): void => {
      window.gtag('event', 'WM_NIGEL_IDENTIFY', {
        ...session,
        timestamp: getTimestamp(),
      });
    },
    message: (status: 'started' | 'ended', options: MessageOptions): void => {
      const { timestamp, newOptions } = getOverridedOptions(options);
      window.gtag('event', 'WM_NIGEL_MESSAGE', {
        ...session,
        ...newOptions,
        status,
        timestamp,
      });
    },
    fileUpload: (status: 'started' | 'ended', options: FileOptions): void => {
      const { timestamp, newOptions } = getOverridedOptions(options);
      window.gtag('event', 'WM_NIGEL_FILE_UPLOAD', {
        ...session,
        ...newOptions,
        timestamp,
      });
    },
    chatMode: (options: ChatModeOptions): void => {
      window.gtag('event', 'WM_NIGEL_CHAT_MODE', {
        ...session,
        ...options,
        timestamp: getTimestamp(),
      });
    },
    scan: (options: ScanOptions): void => {
      window.gtag('event', 'WM_NIGEL_SCAN_DOCUMENTS_WITH_GLEAN', {
        ...session,
        ...options,
        timestamp: getTimestamp(),
      });
    },
    track: (category: string, label: string, options?: Record<string, unknown>): void => {
      const properties = options
        ? {
            category,
            label,
            ...session,
            ...options,
            timestamp: getTimestamp(),
          }
        : {
            category,
            label,
            ...session,
            timestamp: getTimestamp(),
          };
      window.gtag('event', 'WM_NIGEL_OTHER', properties);
    },
  };

  return contextType;
};

const AnalyticsContext: React.Context<AnalyticsContextType | null> =
  createContext<AnalyticsContextType | null>(null);

export const AnalyticsProvider = ({ children }: Props): React.ReactElement => {
  const analyticsContextType = AnalyticsHelper();

  return (
    <AnalyticsContext.Provider value={analyticsContextType}>{children}</AnalyticsContext.Provider>
  );
};

export const useAnalytics = (): AnalyticsContextType => {
  const analyticsContext = useContext(AnalyticsContext);

  if (!analyticsContext) {
    throw new Error('useAnalytics must be used within a AnalyticsProvider');
  }

  return analyticsContext;
};
