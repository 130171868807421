import { DeleteOutline } from '@mui/icons-material';
import { IconButton, MenuItem, Select, styled, Typography } from '@mui/material';
import { TextField } from 'mui-rff';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, FormSpy } from 'react-final-form';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import supersub from 'remark-supersub';

import { processQuestion, userEditSave } from '../../../services/dealGPT';
import type { ProjectRunAnalysisQuestion } from '../../../Types/dealGPT';
import { ProcessReason, UpdatedReason } from '../../../Types/enums';
import formatDate from '../../../util/dateUtils';
import { useFeatures } from '../../Providers/FeatureProvider';
import ModifyTone from './ModifyTone';
import ReadMoreText from './ReadMoreText';
import ReferenceDocuments from './ReferenceDocuments';
import SectionButtons from './SectionButtons';
import UnprocessedChangesDot from './UnprocessedChangesDot';

const HeaderLabel = styled(Typography)({
  marginBottom: '10px',
  color: '#092B49',
  fontWeight: '700',
});

const ReactMarkdownWrapper = styled('div')({
  fontSize: '14px',
  lineHeight: '1.5rem',
  letterSpacing: '0.25px',
  '& > *': {
    fontFamily: 'Noto Sans',
  },

  'a, a:visited, a:hover, a:focus': {
    color: 'inherit',
  },
});

const FlexDiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
});
interface QuestionAnswerProps {
  index: number;
  responseObject: ProjectRunAnalysisQuestion;
  versions: ProjectRunAnalysisQuestion[];
  setIsLoadingThis: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoadingApplyChanges: React.Dispatch<React.SetStateAction<boolean>>;
  reprocessingTriggered: () => void;
  handleDelete: (questionIndex: number) => void;
  suggestSaveOrProcessAll: 'save' | 'process' | null;
  setSuggestSaveOrProcessAll: React.Dispatch<React.SetStateAction<'save' | 'process' | null>>;
}

const QuestionAnswer: React.FC<QuestionAnswerProps> = ({
  index,
  responseObject,
  versions,
  setIsLoadingThis,
  setIsLoadingApplyChanges,
  reprocessingTriggered,
  handleDelete,
  suggestSaveOrProcessAll,
  setSuggestSaveOrProcessAll,
}) => {
  const [analysisQuestionData, setAnalysisQuestionData] =
    useState<ProjectRunAnalysisQuestion>(responseObject);
  const [questionVersions, setQuestionVersions] = useState<ProjectRunAnalysisQuestion[]>(versions);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [currentValues, setCurrentValues] = useState<Record<string, string>>({});
  const [attemptedSaveOrProcessCurrent, setAttemptedSaveOrProcessCurrent] = useState<
    'save' | 'process' | null
  >(null);

  const userInputName = `QA-userInput-${index}`;
  const responseName = `QA-response-${index}`;

  const handleSave = useCallback(
    async (
      values: Record<string, string>,
      reason?: UpdatedReason,
      processReason?: ProcessReason
    ) => {
      const tempAnalysisQuestion: ProjectRunAnalysisQuestion = { ...analysisQuestionData };

      if (reason) {
        tempAnalysisQuestion.updatedReason = reason;
      }
      tempAnalysisQuestion.userInput = values[userInputName];

      // Adding a new question
      if (tempAnalysisQuestion.status === 'INITIAL') {
        if (responseObject.displayOrder) {
          // Use the displayOrder from the responseObject - being controlled from StepPage when user clicks "Add"
          tempAnalysisQuestion.displayOrder = responseObject.displayOrder;
        }
      }

      if (tempAnalysisQuestion.response) {
        tempAnalysisQuestion.response = values[responseName];
      }

      // console.log('tempAnalysisQuestion', tempAnalysisQuestion);
      let updatedQuestion = tempAnalysisQuestion;
      if (reason === UpdatedReason.USER_EDIT || reason === UpdatedReason.USER_INPUT) {
        if (reason === UpdatedReason.USER_INPUT) {
          tempAnalysisQuestion.status = 'INPUT_PENDING';
        }

        const updatedResult = await userEditSave(tempAnalysisQuestion);

        if (updatedResult._data) {
          updatedQuestion = updatedResult._data;
          console.log(reason, 'updatedQuestion in db', updatedQuestion);
          setAnalysisQuestionData(updatedQuestion);
          enqueueSnackbar(`Question ${index + 1} saved successfully`, { variant: 'success' });

          if (reason === UpdatedReason.USER_EDIT) {
            reprocessingTriggered();
          }
        }
      }

      if (
        tempAnalysisQuestion.updatedReason === UpdatedReason.REPROCESS ||
        processReason === ProcessReason.APPLY_CHANGES
      ) {
        enqueueSnackbar(`Processing flash question ${index + 1}`, { variant: 'success' });
        setIsLoadingThis(true);
        if (processReason === ProcessReason.PROCESS_THIS) {
          setAttemptedSaveOrProcessCurrent('process');

          setSuggestSaveOrProcessAll('save');
          await new Promise((resolve) => setTimeout(resolve, 1000));
          setSuggestSaveOrProcessAll(null);
        } else if (processReason === ProcessReason.APPLY_CHANGES) {
          setIsLoadingApplyChanges(true);
        }
        await processQuestion(updatedQuestion, processReason);
        setAttemptedSaveOrProcessCurrent(null);
        reprocessingTriggered();
      }
    },
    [
      analysisQuestionData,
      index,
      reprocessingTriggered,
      responseName,
      responseObject.displayOrder,
      setIsLoadingThis,
      setIsLoadingApplyChanges,
      setSuggestSaveOrProcessAll,
      userInputName,
    ]
  );

  const onSubmit = async () => {
    // setIsLoading(true);
    // const tempAnalysisQuestion = await handleSave(values, UpdatedReason.REPROCESS);
    // if (tempAnalysisQuestion) {
    //   enqueueSnackbar('Your content is being processed', { variant: 'success' });
    //   await processQuestion(tempAnalysisQuestion);
    //   reprocessingTriggered();
    // }
  };

  const handleVersionChange = useCallback(
    async (value: string) => {
      const version = versions.find((v) => v.id === value);
      // console.log('version', version);
      if (version) {
        setAnalysisQuestionData(version);
      }
    },
    [versions]
  );

  useEffect(() => {
    setAnalysisQuestionData(responseObject);

    if (!responseObject.response) {
      setIsEditing(true);
    } else {
      setIsEditing(false);
    }
  }, [responseObject]);

  useEffect(() => {
    // Handle the parent suggesting we should attempt processing or saving
    if (suggestSaveOrProcessAll && currentValues[userInputName]) {
      const attemptSave = async () => {
        setIsLoadingThis(true);
        // console.log('suggestSaveOrProcessAll', suggestSaveOrProcessAll, index + 1);
        // console.log('attemptedSaveOrProcessCurrent', attemptedSaveOrProcessCurrent, index + 1);

        // We want the user to be able to process/reprocess as many times as they want
        if (suggestSaveOrProcessAll === 'process' && attemptedSaveOrProcessCurrent !== 'process') {
          setAttemptedSaveOrProcessCurrent('process');
          setTimeout(async () => {
            enqueueSnackbar(`Processing flash question ${index + 1}`, { variant: 'success' });
            const tempAnalysisQuestion: ProjectRunAnalysisQuestion = { ...analysisQuestionData };
            tempAnalysisQuestion.updatedReason = UpdatedReason.REPROCESS;
            tempAnalysisQuestion.userInput = currentValues[userInputName];
            tempAnalysisQuestion.response = currentValues[responseName];

            await processQuestion(tempAnalysisQuestion, ProcessReason.PROCESS_THIS);
            setAttemptedSaveOrProcessCurrent(null);
            reprocessingTriggered();
          }, index * 1500);
        } else if (
          suggestSaveOrProcessAll === 'save' &&
          attemptedSaveOrProcessCurrent === null &&
          currentValues[userInputName] !== null &&
          analysisQuestionData.response === null
        ) {
          setAttemptedSaveOrProcessCurrent('save');
          await handleSave(currentValues, UpdatedReason.USER_INPUT);
        }
      };
      attemptSave();
    }
  }, [
    attemptedSaveOrProcessCurrent,
    currentValues,
    handleSave,
    reprocessingTriggered,
    setIsLoadingThis,
    suggestSaveOrProcessAll,
    index,
    analysisQuestionData,
    responseName,
    userInputName,
  ]);

  useEffect(() => {
    // console.log('versions updated', versions);
    let activeVersions = versions;

    if (versions.some((version) => version.status === 'ANSWERED')) {
      activeVersions = versions.filter((version) => version.status === 'ANSWERED');
    }
    setQuestionVersions(activeVersions);
    if (activeVersions[0]) {
      handleVersionChange(activeVersions[0].id);
    }
  }, [handleVersionChange, versions]);

  const initialValues = useMemo(
    () => ({
      [userInputName]: analysisQuestionData.userInput,
      [responseName]: analysisQuestionData.response,
    }),
    [userInputName, responseName, analysisQuestionData.userInput, analysisQuestionData.response]
  );
  const features = useFeatures();
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <FormSpy
            subscription={{ values: true }}
            onChange={(props) => {
              // I don't have a really good reason to be using this eslint-disable, when we get a chance
              // lets do what it takes to not have to use this disable line
              // eslint-disable-next-line react/prop-types
              setCurrentValues(props.values);
            }}
          />
          <FlexDiv>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
              <div
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
              >
                <HeaderLabel>Enter in Key Question:</HeaderLabel>
                <IconButton onClick={() => handleDelete(index)} sx={{ float: 'right' }}>
                  <DeleteOutline
                    sx={{
                      border: '1px solid #B8C1C7',
                      borderRadius: '4px',
                      padding: '12px',
                      fontSize: '20px',
                      height: '100%',
                    }}
                  />
                </IconButton>
              </div>

              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ fontWeight: '700', color: '#092B49', marginRight: '8px' }}>
                  {index + 1})
                </Typography>
                {!analysisQuestionData.response ? (
                  <TextField name={userInputName} multiline />
                ) : (
                  <Typography>{analysisQuestionData.userInput}</Typography>
                )}
              </div>
              {!responseObject.response && (
                <SectionButtons
                  handleSave={handleSave}
                  values={values}
                  hasGenerated={!!analysisQuestionData.response}
                  handleEdit={setIsEditing}
                  isEditing={isEditing}
                  showEditButton={false}
                  showSaveButton={false}
                  allowGeneration={!!values[userInputName]}
                />
              )}
            </div>
            {!!responseObject.response && (
              <FlexDiv>
                <div>
                  <FlexDiv>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        gap: '12px',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '14px',
                          fontWeight: '700',
                          color: '#757575',
                        }}
                      >
                        RESPONSE
                      </Typography>
                      <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                        <Select
                          value={analysisQuestionData.id}
                          onChange={(event) => handleVersionChange(event.target.value)}
                          size="small"
                        >
                          {questionVersions.map((version) => (
                            <MenuItem
                              value={version.id}
                              key={version.id}
                              selected={version.id === analysisQuestionData.id}
                            >
                              Generated on {version.createdAt && formatDate(version.createdAt)} |{' '}
                              {version.updatedReason}
                            </MenuItem>
                          ))}
                        </Select>
                        {questionVersions.length > 0 &&
                          questionVersions[0].unprocessedDownstreamChanges && (
                            <UnprocessedChangesDot />
                          )}
                      </div>
                      <ModifyTone
                        question={analysisQuestionData}
                        setIsLoading={setIsLoadingThis}
                        reprocessingTriggered={reprocessingTriggered}
                      />
                    </div>
                    <SectionButtons
                      handleSave={handleSave}
                      values={values}
                      hasGenerated={!!analysisQuestionData.response}
                      handleEdit={setIsEditing}
                      isEditing={isEditing}
                      question={analysisQuestionData}
                    />
                    {isEditing ? (
                      <TextField name={responseName} multiline />
                    ) : (
                      <ReactMarkdownWrapper>
                        <ReactMarkdown remarkPlugins={[remarkGfm, supersub]}>
                          {analysisQuestionData.response || ''}
                        </ReactMarkdown>
                      </ReactMarkdownWrapper>
                    )}
                  </FlexDiv>
                </div>
                <ReferenceDocuments documents={analysisQuestionData.documents} />

                {features.deal_gpt_enriched_prompt_display && (
                  <>
                    <Typography sx={{ fontWeight: '700', color: '#092B49', marginTop: '20px' }}>
                      Enriched Prompt:
                    </Typography>
                    <div style={{ marginBottom: '15px' }}>
                      <ReadMoreText
                        text={analysisQuestionData.enrichedPrompt || ''}
                        maxLength={120}
                      />
                    </div>
                  </>
                )}
              </FlexDiv>
            )}
          </FlexDiv>
        </form>
      )}
    />
  );
};

export default QuestionAnswer;
