import { DeleteOutlined, InsertDriveFileOutlined, MoreHoriz } from '@mui/icons-material';
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';

import { deleteEmbeddingsFile } from '../../services/embeddings';
import { AzureIndexCitation } from '../../Types/azureIndexCitations';
import { EmbeddingsFile } from '../../Types/embeddings';
import DeleteConfirmationDialog from '../DeleteConfirmationDialog';

export type FileCardProps = {
  file: EmbeddingsFile;
  onEdit: () => void;
};

export const createCitationFilepath = (citation: AzureIndexCitation, index: number) => {
  let citationFilename = '';

  if (citation.filepath && citation.chunk_id) {
    if (citation.filepath.length > 50) {
      const citationLength = citation.filepath.length;
      citationFilename = `${citation.reindex_id}. ${citation.filepath.substring(
        0,
        20
      )}...${citation.filepath.substring(citationLength - 20)} - Part ${
        parseInt(citation.chunk_id) + 1
      }`;
    } else {
      citationFilename = `${citation.reindex_id}. ${citation.filepath} - Part ${
        parseInt(citation.chunk_id) + 1
      }`;
    }
  } else if (citation.filepath && citation.reindex_id) {
    citationFilename = `${citation.reindex_id}. ${citation.filepath} - Part ${citation.reindex_id}`;
  } else {
    citationFilename = `Citation ${index}`;
  }
  return citationFilename;
};

export default function FileCard({ file, onEdit }: FileCardProps) {
  const [anchorContextMenu, setAnchorContextMenu] = useState<null | HTMLElement>(null);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const menuIconSize: object = { height: '18px', width: '16px' };
  const menuIconStyle = {
    ...menuIconSize,
    paddingRight: '10px',
  };

  const handleOpenFileContextMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorContextMenu(event.currentTarget);
  };

  const handleCloseFileContextMenu = () => {
    setAnchorContextMenu(null);
  };

  const handleFileDelete = async (fileId: EmbeddingsFile['id']) => {
    await deleteEmbeddingsFile(fileId);
    setAnchorContextMenu(null);
    onEdit();
  };

  return (
    <Card sx={{ width: '250px' }}>
      <CardHeader
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingTop: '10px',
          paddingBottom: '10px',
        }}
        title={
          <div style={{ display: 'flex' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <InsertDriveFileOutlined fontSize="small" style={{ marginRight: '10px' }} />
                <Typography variant="caption" fontSize="small">
                  {file.name}
                </Typography>
              </div>
              <div onClick={(e) => e.stopPropagation()}>
                <DeleteConfirmationDialog
                  isOpen={deleteOpen}
                  setIsOpen={setDeleteOpen}
                  onDelete={() => handleFileDelete(file.id)}
                />
                <Tooltip title={<Typography variant="caption"> Actions </Typography>}>
                  <IconButton onClick={handleOpenFileContextMenu} sx={{ p: 0 }}>
                    <MoreHoriz sx={{ height: '20px', width: '20px' }} />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '32px' }}
                  anchorEl={anchorContextMenu}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorContextMenu)}
                  onClose={handleCloseFileContextMenu}
                >
                  <MenuItem onClick={() => setDeleteOpen(true)}>
                    <DeleteOutlined sx={menuIconStyle} />
                    <Typography>Delete</Typography>
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </div>
        }
      ></CardHeader>
      <CardContent>
        <Typography variant="caption" fontSize="small">
          {`Uploaded: ${new Date(file.createdAt).toLocaleDateString('en-US')} | ${
            Math.ceil((file.size / (1024 * 1024)) * 10) / 10
          }MB`}
        </Typography>
      </CardContent>
    </Card>
  );
}
