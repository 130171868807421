import styled from '@emotion/styled';
import formbricks from '@formbricks/js/app';
import {
  AdminPanelSettings,
  BuildOutlined,
  DescriptionOutlined,
  FeedbackOutlined,
  LogoutOutlined,
  Menu as MenuIcon,
  OpenInNew as ExternalLinkIcon,
  OpenInNew,
  Person,
  VerifiedUser,
} from '@mui/icons-material';
import {
  AppBar,
  Box,
  Container,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import NigelLogo from '../assets/wm-logo.png';
import { selectAppStatus, setLLM } from '../redux/reducers/appStatusReducer';
import { AppDispatch } from '../redux/store';
import { useIsMobile } from '../util/useIsMobile';
import LlmSelect from './LlmSelect';
import { useAuth } from './Providers/AuthProvider';
import { useFeatures } from './Providers/FeatureProvider';
import { useNavBarRoutes } from './Providers/NavbarRoutesProvider';

export enum NavBarMode {
  NIGEL = 'NIGEL',
  DEALGPT = 'DEALGPT',
  BLANK = 'BLANK',
}

export type PageRoute = {
  name: string;
  route: string;
  external?: boolean;
};

const IconContainer = styled('span')({
  color: 'gray',
  transition: 'all 0.2s ease-in-out',
  display: 'flex',

  span: {
    color: 'black',
    marginLeft: '5px',
  },
});

const LogoContainer = styled('div')({
  width: '140px',
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
  cursor: 'pointer',
  marginRight: '10px',
});

const RoutesContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  minHeight: 'inherit',
  alignItems: 'center',
});

const NavBarContainer = styled('div')({
  display: 'flex',
  gap: '5px',
  alignItems: 'center',
  width: '100%',
  minHeight: 'inherit',
  justifyContent: 'space-between',
});

const StyledAppBar = styled(AppBar)({
  backgroundColor: 'white',
  color: 'black',
  boxShadow: '0px 2px 2px 2px rgba(16, 24, 40, 0.05)',
  zIndex: '1000',
  left: 0,
  position: 'relative',
});

const NavBarMenuItem = styled(MenuItem)({
  '&:hover': {
    outline: '1px solid #103e6a',
    backgroundColor: 'white',
  },

  '.MuiTouchRipple-child': {
    backgroundColor: '#cce6ff',
  },

  borderRadius: '4px',
});

const ProfilePhotoButton = styled(IconButton)({
  position: 'relative',
});

const ProfilePhoto = styled('img')({
  width: '40px',
  height: '35px',
  borderRadius: '50%',
});

const AdminIcon = styled(VerifiedUser)({
  position: 'absolute',
  left: '27px',
  top: '25px',
  color: 'green',
  height: '15px',
  width: '14px',
});

const NavBar = ({ mode = NavBarMode.NIGEL }: { mode: NavBarMode }): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  const { routes, setAppMode } = useNavBarRoutes();
  const { logout, isAdmin, fetchProfilePhoto } = useAuth();
  const features = useFeatures();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { llm } = useSelector(selectAppStatus);

  const navigate = useNavigate();
  const location = useLocation();
  const [profilePhoto, setProfilePhoto] = useState<string | null>(() =>
    localStorage.getItem('profilePhoto')
  );

  const isMobile = useIsMobile();

  useEffect(() => {
    const updateProfilePhoto = () => {
      setProfilePhoto(localStorage.getItem('profilePhoto'));
    };

    window.addEventListener('storage', updateProfilePhoto);

    return () => {
      window.removeEventListener('storage', updateProfilePhoto);
    };
  }, []);

  useEffect(() => {
    setAppMode(mode);
  });

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleFeedback = () => {
    formbricks.track('feedback_button_clicked');
  };

  const handleAdminPage = () => {
    setAnchorElUser(null);
    navigate('/admin-page');
  };

  const handleProfileSettings = () => {
    navigate('/profile-settings');
  };

  const handleReleaseNotes = () => {
    setAnchorElUser(null);
    navigate('/release-notes');
  };

  const handleLogout = () => {
    setAnchorElUser(null);
    logout();
  };

  const handleProfilePhotoError = () => {
    setProfilePhoto('');
    fetchProfilePhoto();
  };

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setDrawerOpen(open);
    document.body.style.overflow = open ? 'hidden' : 'auto'; // Prevent scrolling when the drawer is open
  };

  const drawerList = () => (
    <Box
      // Added a marginTop to the Mobile Right Side Nav Drawer,
      // because otherwise there would be some white space between
      // the first menu item entry and the top of the drawer
      sx={{ width: '100%', marginTop: '-8px' }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {routes.map((page) => (
          <ListItem
            button
            key={page.name}
            onClick={() => {
              if (page.external) {
                window.open(page.route, '_blank');
              } else {
                navigate(page.route);
              }
            }}
            sx={{
              backgroundColor:
                isMobile && location.pathname.startsWith(page.route) ? '#D3EAFF' : 'inherit',
            }}
          >
            {isMobile && page.external && (
              <ExternalLinkIcon sx={{ marginRight: '10px' }} /> // Add icon only on mobile and for external links
            )}
            <ListItemText primary={page.name} />
          </ListItem>
        ))}
      </List>
      <Divider sx={{ marginX: '10px' }} />
      <List>
        <ListItem button onClick={() => navigate('/profile-settings')}>
          <Person />
          <ListItemText primary="Profile Settings" sx={{ marginLeft: '10px' }} />
        </ListItem>
        <ListItem button onClick={handleFeedback}>
          <FeedbackOutlined />
          <ListItemText primary="Submit Feedback" sx={{ marginLeft: '10px' }} />
        </ListItem>
        <ListItem button onClick={() => navigate('/release-notes')}>
          <DescriptionOutlined />
          <ListItemText primary="Release Notes" sx={{ marginLeft: '10px' }} />
        </ListItem>
        <ListItem button onClick={handleLogout}>
          <LogoutOutlined />
          <ListItemText primary="Logout" sx={{ marginLeft: '10px' }} />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <StyledAppBar>
      <Container maxWidth={false}>
        <Toolbar disableGutters>
          <NavBarContainer>
            {isMobile ? (
              <>
                <LogoContainer onClick={() => navigate('/')}>
                  <img src={NigelLogo} style={{ height: '30px' }} />
                </LogoContainer>
                <Box sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
                  {mode !== NavBarMode.DEALGPT && (
                    <LlmSelect
                      value={llm}
                      mobileNavbarLlm={true}
                      onLlmChange={(newVal) => dispatch(setLLM(newVal))}
                    />
                  )}
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{
                      height: '30px',
                      alignSelf: 'center',
                      marginX: '10px',
                      borderRightWidth: '2px',
                    }}
                  />
                  <IconButton onClick={toggleDrawer(true)}>
                    <MenuIcon />
                  </IconButton>
                </Box>
                <Drawer
                  anchor="right"
                  open={drawerOpen}
                  onClose={toggleDrawer(false)}
                  BackdropProps={{
                    invisible: true,
                  }}
                  PaperProps={{
                    style: {
                      width: '80vw',
                      top: '64px',
                      height: 'calc(100vh - 64px)',
                      boxShadow: 'none',
                      position: 'absolute',
                    },
                  }}
                >
                  {drawerList()}
                </Drawer>
              </>
            ) : (
              <>
                <RoutesContainer>
                  {routes.map((page, index) => (
                    <NavBarMenuItem
                      key={page.name}
                      id={
                        page.external
                          ? `${mode}-${page.name.split(' ').join('-')}`
                          : page.route.substring(1)
                      }
                      onClick={() => {
                        page.external ? window.open(page.route, '_blank') : navigate(page.route);
                      }}
                      selected={location.pathname.startsWith(page.route)}
                      tabIndex={index + 1}
                    >
                      <Typography
                        textAlign="center"
                        sx={{
                          fontWeight: location.pathname.startsWith(page.route) ? '500' : '400',
                        }}
                      >
                        {page.external && (
                          <OpenInNew
                            sx={{ fontSize: '20px', marginRight: '2px', verticalAlign: 'text-top' }}
                          />
                        )}
                        {page.name}
                      </Typography>
                    </NavBarMenuItem>
                  ))}
                </RoutesContainer>
                <Tooltip
                  title={<Typography variant="caption"> Submit Feedback </Typography>}
                  PopperProps={{ keepMounted: true }}
                  id={'submit-feedback-button-label'}
                  sx={{ height: '40px', width: '40px' }}
                >
                  <IconButton
                    aria-labelledby={'submit-feedback-button-label'}
                    tabIndex={1}
                    onClick={() => handleFeedback()}
                  >
                    <FeedbackOutlined />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={<Typography variant="caption"> Open settings </Typography>}
                  sx={{ height: '40px', width: '40px' }}
                >
                  <ProfilePhotoButton onClick={handleOpenUserMenu}>
                    {isAdmin && profilePhoto ? (
                      <>
                        <ProfilePhoto src={profilePhoto} alt="Open settings" />
                        <AdminIcon />
                      </>
                    ) : isAdmin && !profilePhoto ? (
                      <AdminPanelSettings />
                    ) : profilePhoto ? (
                      <ProfilePhoto
                        src={profilePhoto}
                        onError={handleProfilePhotoError}
                        alt="Profile"
                      />
                    ) : (
                      <Person />
                    )}
                  </ProfilePhotoButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem onClick={() => handleProfileSettings()}>
                    <Typography textAlign="center">
                      <IconContainer>
                        <Person />
                        <span>Profile Settings</span>
                      </IconContainer>
                    </Typography>
                  </MenuItem>

                  {(isAdmin || features.stats_page_access || features.file_management_access) && (
                    <MenuItem onClick={() => handleAdminPage()}>
                      <Typography textAlign="center">
                        <IconContainer>
                          <BuildOutlined />
                          <span>Admin</span>
                        </IconContainer>
                      </Typography>
                    </MenuItem>
                  )}

                  <MenuItem onClick={() => handleReleaseNotes()}>
                    <Typography textAlign="center">
                      <IconContainer>
                        <DescriptionOutlined />
                        <span>Release Notes</span>
                      </IconContainer>
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={() => handleLogout()}>
                    <Typography textAlign="center">
                      <IconContainer>
                        <LogoutOutlined />
                        <span>Logout</span>
                      </IconContainer>
                    </Typography>
                  </MenuItem>
                </Menu>
              </>
            )}
          </NavBarContainer>
        </Toolbar>
      </Container>
    </StyledAppBar>
  );
};

export default NavBar;
